import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import CheckBox from '../components/CheckBox'
import ArrowLeft from "../assets/icons/ArrowLeft.png";

export default function CauseDetails(props: any) {
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = React.useState(false)
  const [loading1, setLoading1] = React.useState(false)
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies] = useCookies();

  const [name, setName] = useState()
  const [description, setDescription] = useState()
  const [causeBelongingOptions, setCauseBelongingOptions] = useState()
  const [causeBelongingSelectedOption, setCauseBelongingSelectedOption] = useState()
  const [isZakat, setIsZakat] = useState()
  const [logo, setLogo] = useState()
  const [icon, setIcon] = useState()
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [detailsData, setDetailsData] = useState([])
  const [cause, setCause] = useState()

  useEffect(() => {
    let index = state.causes.findIndex((oneCause) => {
      if (oneCause._id == props.id) {
        return true;
      }
    })
    let cause = state.causes[index];
    setCause(cause)

    let charities = state.charities;
    let selectedCharityIndex = charities.findIndex((oneCharity) => {
      if (oneCharity._id == cause.parentCharity) { return true }
    })

    const causeDetails = [
      { name: "Cause Name", value: cause.name },
      { name: "Cause Logo", value: cause.image, valueType: "image" },
      { name: "Cause Icon", value: cause.icon, valueType: "image" },
      { name: "Cause Belong to", value: charities[selectedCharityIndex]?.name },
      { name: "Cause Short Description", value: cause?.description },
      { name: "Cause Zakat eligible", value: cause?.isZakat ? "On" : "Off" },
    ]
    setDetailsData(causeDetails)

    // Prefill form:
    setIsZakat(cause.isZakat)
    setIcon(cause.icon)
    setLogo(cause.image)
    setName(cause.name)
    setDescription(cause.description)
    setCauseBelongingSelectedOption(charities[selectedCharityIndex]?.name)
    setCauseBelongingOptions(state.charities.map((oneCharity) => oneCharity?.name))

  }, [])

  const saveHandler = () => {
    setLoading(true)
    const data = {
      name,
      parentCharity: findIdOfCharity(causeBelongingSelectedOption),
      image: logo,
      description,
      icon,
      isActive: true,
      isZakat,
      status: 1,
    };
    axios({
      method: "post",
      url: "/editcause/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        setLoading(false)
        Toast.fire({
          title: `Cause ${name} has been edited`,
          icon: "success",
        });
        let newCauses = [...state.causes]
        let index = newCauses.findIndex((oneCause) => {
          if (oneCause._id == props.id) { return true }
        })
        newCauses[index] = {
          ...cause,
          description,
          icon,
          image: logo,
          isZakat,
          name,
        }
        dispatch({ type: 'causes', causes: newCauses })
        props.backToCauses()
      })
      .catch(() => {
        setLoading(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const findIdOfCharity = (name: string) => {
    return state.charities[state.charities.findIndex((oneCharity) => name == oneCharity.name)]._id;
  }

  const editHandler = () => {
    setIsEditClicked(true)
  }

  const backHandler = () => {
    props.backToCauses()
  }

  const deleteHandler = () => {
    setLoading1(true)
    axios({
      method: "get",
      url: "/deletecause/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async (response: any) => {
        setLoading1(false)
        Toast.fire({
          title: `Cause "${name}" has been deleted`,
          icon: "success",
        });
        let newCauses = [...state.causes]
        let index = newCauses.findIndex((oneCause) => {
          if (oneCause._id == props.id) {
            return true;
          }
        })
        newCauses.splice(index, 1);
        dispatch({ type: 'causes', causes: newCauses })
        props.backToCauses()
      })
      .catch((err) => {
        setLoading1(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="flex flex-row pb-3">
          <img
            onClick={backHandler}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
          />
          {/* <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 "> */}
          <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {cause?.name}
          </p>
          {/* </div> */}
        </div>
        <div className="bg-white px-8">
          {isEditClicked ?
            <div className='bg-white px-8'>
              <div className='bg-white pt-8'>
                <InputWithLabel value={name} onChange={(e) => setName(e.target.value)} inputType="text" label="Cause Title" placeholder="Type cause" />
                <InputWithLabel
                  inputType="file"
                  setUrl={setLogo}
                  label="Cause Logo"
                />
                <InputWithLabel
                  inputType="file"
                  setUrl={setIcon}
                  label="Cause Icon"
                />
                <InputWithLabel onChange={(obj) => setCauseBelongingSelectedOption(obj.value)} inputType="dropdown" label="Cause Belong to" placeholder="Select organization" selectedOption={causeBelongingSelectedOption} options={causeBelongingOptions} />
                <InputWithLabel value={description} onChange={(e) => setDescription(e.target.value)} inputType="textarea" label="Cause Short Description" placeholder="Type Description" />
              </div>
              <div className='mt-5 font-Poppins'> <CheckBox setIsChecked={() => setIsZakat(!isZakat)} isChecked={isZakat} label='Cause Zakat eligible' /></div>
            </div>
            : <DetailsForm data={detailsData} />
          }
          <div className="pb-4 mt-10">
            <div className="inline-block w-36 mr-6"><Button loading={loading} action={isEditClicked ? () => saveHandler() : () => editHandler()} title={isEditClicked ? "Save changes" : "Edit"} /></div>
            <div className="inline-block w-24"><Button loading={loading1} type="delete" action={deleteHandler} title="Delete" /></div>
          </div>
        </div>
      </div>
    </>
  );
}
