import React, {useState, useEffect} from 'react'
import CheckBox from '../components/CheckBox'
import Button from '../components/Button'
import { useCookies } from "react-cookie";
import axios from '../utils/axios'
import Swal from "sweetalert2";
import {InputWithLabel} from '../components/Input'
import ReactColorPicker from '@super-effective/react-color-picker';
import { Context } from "../utils/store";


export default function AddLunarMonth(props:any) {

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
    });
    const [number, setNumber] = React.useState()
    const { state, dispatch } = React.useContext(Context);
    const [cookies] = useCookies();
    const [check, setCheck] = React.useState(false)
    const [name, setName] = useState()
    const [loading, setLoading] = useState(false)
    const [color1, setColor1] = useState("#3cd6bf")
    const [color2, setColor2] = useState("#3cd6bf")
    const [gradient, setGradient] = useState()

    const addNow = () => {
      setLoading(true)
        const params = {
          name,
          status: 1,
          isActive: check,
          monthNumber:number,
          gradient: {"boxColor1": color1, "boxColor2": color2},
        }
          axios({
            method: "post",
            url: "/islamicmonths",
            headers: {
              Authorization: `Bearer ${cookies.tokenId}`,
            },
            data: params,
          })
            .then(async (response: any) => {
              console.log(response);
              Toast.fire({
                  title: `Month ${name} has been added`,
                  icon: "success",
              })
              let newIslamicMonths = [...state.islamicmonths]
              newIslamicMonths.push({...response.data.response})
              newIslamicMonths.sort((a, b)=>{
                return a.monthNumber-b.monthNumber;
              })
              dispatch({ type: 'islamicmonths', islamicmonths: newIslamicMonths })
              props.backToMonths();
              setName('')
              setLoading(false)
            })
            .catch((err) =>{
              Toast.fire({
                title: "Error",
                icon: "error",
              })
              setLoading(false)
            });
    }

    useEffect(()=>{
      console.log('Color changed')

      setGradient(123)
    }, [color1, color2])

    return(
        <>
            <div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className="pt-4 rounded-3xl relative z-20">
                <div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
                    <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Add Lunar Month</p>    
                </div>
                <div className='bg-white px-8'> 
                    <div className='bg-white grid pt-8'>
                      <InputWithLabel value={name} onChange={(e)=>setName(e.target.value)} inputType="text" label="Lunar Month Title" placeholder="Type Lunar Title"/>
                      <div className="grid gap-y-5">
                        <label className='2xl:text-base text-sm transform translate-y-4'>Color 1</label>
                          <div className="w-96">
                            <ReactColorPicker className="w-96" color={color1} onChange={(updatedColor)=>setColor1(updatedColor)} />
                          </div>
                        <label className='2xl:text-base text-sm transform translate-y-4'>Color 2</label>
                          <div className="w-96">
                            <ReactColorPicker color={color2} onChange={(updatedColor)=>setColor2(updatedColor)} />
                          </div>
                          <label className='2xl:text-base text-sm transform translate-y-4'>Gradient</label>
                        <div style={{ backgroundImage: `linear-gradient(to right, ${color1}, ${color2})`}} className="w-96 h-12"></div>
                    </div>  
                    <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Month Number</p>
              <input
                onChange={(e) => setNumber(e.target.value)}
                type="number"
                style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}
                className={`mt-auto mb-auto table-cell rounded-md w-72 2xl:mr-0 flex-grow    mb-5 2xl:mb-0 focus:ring-white  w-full  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                placeholder='Month Number'
              />
            </div>
          </div>  
                    </div>
                    <div className='mt-10 mb-10 font-Poppins relative z-20'> <CheckBox label='Show in checkout' isChecked={check} setIsChecked={()=>setCheck(!check)} /></div>
                    <div className='md:w-40 pb-4 mt-10'> <Button loading={loading} action={addNow} title='Add Now'/></div>
                </div>
            </div>
        </>
    )
}