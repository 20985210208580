/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import CheckBox from "../components/CheckBox";
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import Dropdown from "react-dropdown";
import "../styles/dropdown.css";
import moment from "moment";
import DatePicker from "react-modern-calendar-datepicker";

export default function LunarDatesDetails(props: any) {
  const { state, dispatch } = useContext(Context);
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies] = useCookies();
  const [causeBelongingOptions, setCauseBelongingOptions] = useState<any>();
  const [notificationDate, setNotificationDate] = useState();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [lunarDateName, setLunarDateName] = React.useState();
  const [monthName, setMonthName] = React.useState();
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [monthData, setMonthData] = React.useState<any>();
  const [status, setStatus] = React.useState();
  const [newMonthId, setNewMonthId] = React.useState();
  const [showInCheckout, setShowInCheckout] = React.useState<any>();
  const [isNight, setIsNight] = React.useState<any>();
  const [dayOfMonth, setDayOfMonth] = React.useState();
  const [calendarDate, setCalendarDate] = React.useState();
  const [isActive, setIsActive] = useState();

  //Подключение месяцев
  React.useEffect(() => {
    axios.get("/islamicmonths").then((response) => {
      setMonthData(response.data.response);
    });
  }, []);
  //Поиск выбранного месяца по ID дня месяца
  React.useEffect(() => {
    if (monthData) {
      monthData.forEach((oneCharity: any) => {
        if (oneCharity._id == props.id) {
          setMonthName(oneCharity.name);
          setNewMonthId(oneCharity._id);
        }
      });
    }
  }, [monthData]);

  //Установление значений до редактирования, когда получено название месяца
  React.useEffect(() => {
    setLunarDateName(props.name);
    setStatus(props.status);

    let index = state.islamicdays.findIndex((oneDay: any) => {
      if (oneDay._id === props.dayId) {
        return true;
      }
    });
    let islamicDay = state.islamicdays[index];

    const lunarDatesDetails: any = [
      { name: "Lunar Date", value: props.name },
      { name: "Lunar Month", value: monthName },
      {
        name: "Calendar Date Map",
        value: moment(props.calendar).format("MM/DD/YYYY"),
      },
      {
        name: "Notification Date",
        value: moment(props.notification).format("MM/DD/YYYY"),
      },
      { name: "Day of Month", value: islamicDay.dayOfMonth },
      {
        name: "Shown in Checkout",
        value: islamicDay.showInCheckout ? "Yes" : "No",
      },
      { name: "Night schedule", value: islamicDay.isNight ? "Yes" : "No" },
    ];
    setDetailsData(lunarDatesDetails);
    setCauseBelongingOptions(
      monthData?.map((oneCharity: any) => oneCharity.name)
    );
      console.log(props)
    // Prefill form:
    setCalendarDate(props.calendar)
    setNotificationDate(props.notification);
    setLunarDateName(islamicDay.name);
    setShowInCheckout(islamicDay.showInCheckout);
    setIsNight(islamicDay.isNight);
    setDayOfMonth(islamicDay.dayOfMonth);
    setIsActive(islamicDay.isActive);
  }, [monthName]);

  const [newMonthName, setNewMonthName] = React.useState();
  useEffect(() => {
    if (monthData) {
      monthData.forEach((oneCharity: any) => {
        if (oneCharity.name === newMonthName) {
          setNewMonthId(oneCharity._id);
        }
      });
    }
  }, [newMonthName]);
  const saveHandler = () => {
    setLoading1(true);
    const data = {
      islamicMonthId: newMonthId,
      name: lunarDateName,
      calendarDate: moment(calendarDate).startOf('day').format(),
      dayOfMonth,
      showInCheckout,
      isNight,
      notificationDate: moment(notificationDate).startOf('day').format(),
      status: true,
      isActive,
    };
    console.log("data to be sent for editing islamic day", data);
    axios({
      method: "post",
      url: `/editislamicday/${props?.dayId}`,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data,
    })
      .then(async () => {
        Toast.fire({
          title: `Lunar Date ${lunarDateName} has been edited`,
          icon: "success",
        });
        let newIslamicDays = [...state.islamicdays];
        let index = newIslamicDays.findIndex((oneDay) => {
          if (oneDay._id === props.dayId) {
            return true;
          }
        });
        newIslamicDays[index] = {
          ...newIslamicDays[index],
          ...data,
        };
        dispatch({ type: "islamicdays", islamicdays: newIslamicDays });
        props.backToLunarDates();
        setLoading1(false);
      })
      .catch(() => {
        Toast.fire({
          title: "Error",
          icon: "error",
        });
        setLoading1(false);
      });
  };

  const editHandler = () => {
    setIsEditClicked(true);
  };

  const deleteHandler = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `/deleteislamicday/${props?.dayId}`,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async () => {
        setLoading(false);
        Toast.fire({
          title: `Lunar Date "${props.name}" has been deleted`,
          icon: "success",
        });
        let newIslamicDays = [...state.islamicdays];
        let index = newIslamicDays.findIndex((oneDay) => {
          if (oneDay._id == props.dayId) {
            return true;
          }
        });
        newIslamicDays.splice(index, 1);
        dispatch({ type: "islamicdays", islamicdays: newIslamicDays });
        props.backToLunarDates();
      })
      .catch(() => {
        setLoading(false);
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const renderCustomNotificationInput = (ref: any) => {
    return (
      <input
        ref={ref}
        value={notificationDate ? moment(notificationDate).format("MM/DD/YYYY") : null}
        placeholder="MM/DD/YY"
        style={{
          width: "12rem",
          backgroundColor: "#fafafa",
          textAlign: "center",
          padding: ".3rem .7rem",
          boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)",
          borderRadius: "5px",
          color: "black",
          outline: "none",
        }}
        className="my-custom-input-class z-30 relative" // a styling class
      />
    );
  };

  const renderCustomCalendarDateInput = (ref: any) => {
    return (
      <input
        ref={ref}
        value={calendarDate ? moment(calendarDate).format("MM/DD/YYYY") : null}
        placeholder="MM/DD/YY"
        style={{
          width: "12rem",
          backgroundColor: "#fafafa",
          textAlign: "center",
          padding: ".3rem .7rem",
          boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)",
          borderRadius: "5px",
          color: "black",
          outline: "none",
        }}
        className="my-custom-input-class z-30 relative" // a styling class
      />
    );
  };

  const calendarDateHandler = (value: any) => {
    // value = moment(value).format("MM/DD/YYYY");
    const day = ("0" + value.day).slice(-2);
    const month = ("0" + value.month).slice(-2);
    const year = value.year;

    const date: any = month + "/" + day + "/" + year;
    setCalendarDate(date);
  };

  const notificationDateHandler = (value: any) => {
    console.log(value);
    // value = moment(value).format("MM/DD/YYYY");
    const day = ("0" + value.day).slice(-2);
    const month = ("0" + value.month).slice(-2);
    const year = value.year;
    const date: any = month + "/" + day + "/" + year;
    console.log(date);
    setNotificationDate(date);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="flex flex-row pb-3">
          <img
            onClick={() => props.backToLunarDates()}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
          />
          <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {props.name}
          </p>
        </div>
        <div className="bg-white px-8">
          {isEditClicked && monthName ? (
            <div className="bg-white px-8">
              <div className="bg-white">
                <div className="pt-8">
                  <div className="flex flex-row items-center">
                    <p className="w-56 font-Poppins 2xl:text-base text-sm">
                      Lunar Date
                    </p>
                    <input
                      value={lunarDateName}
                      onChange={(e: any) => setLunarDateName(e.target.value)}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                      }}
                      className={`mt-auto rounded-md w-72 2xl:mr-0 mb-5 2xl:mb-0 focus:ring-white  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                      placeholder="Lunar Date Name"
                    />
                  </div>
                </div>
                <div className="">
                  <div className="flex flex-row items-center">
                    <p className="w-56 font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell">
                      Lunar Month
                    </p>
                    <div className="relative z-40 w-72">
                      {causeBelongingOptions && (
                        <Dropdown
                          value={monthName}
                          options={causeBelongingOptions}
                          onChange={(e: any) => setNewMonthName(e.value)}
                          placeholder="Choose Lunar Month"
                          className="font-Poppins text-sm 2xl:text-base"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex flex-row items-center">
                    <p className="w-56 font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell">
                      Calendar Date Map
                    </p>
                    <div className="w-72">
                      <div className="inline-block md:justify-self-end z-30">
                        <DatePicker
                          value={null}
                          onChange={(value) => calendarDateHandler(value)}
                          calendarPopperPosition="bottom"
                          renderInput={({ ref }) =>
                            renderCustomCalendarDateInput(ref)
                          }
                          shouldHighlightWeekends
                          wrapperClassName="z-10"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="flex flex-row">
                    <p className="w-56 font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell">
                      Notification Date
                    </p>
                    <div className="-ml-10">
                      <div className="flex flex-row py-6">
                        <div className="inline-block md:justify-self-end  ml-10 z-30">
                          <DatePicker
                            value={null}
                            onChange={(value) => notificationDateHandler(value)}
                            calendarPopperPosition="bottom"
                            renderInput={({ ref }) =>
                              renderCustomNotificationInput(ref)
                            }
                            shouldHighlightWeekends
                            wrapperClassName="z-10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-8">
                  <div className="flex flex-row items-center">
                    <p className="w-56 font-Poppins 2xl:text-base text-sm">
                      Day of Month
                    </p>
                    <input
                      value={dayOfMonth}
                      onChange={(e: any) => setDayOfMonth(e.target.value)}
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                      }}
                      className={`mt-auto rounded-md w-72 2xl:mr-0 mb-5 2xl:mb-0 focus:ring-white  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                      placeholder="Day of Month"
                    />
                  </div>
                </div>
                <div className="mt-5 mb-10 font-Poppins">
                  <CheckBox
                    label="Show in checkout"
                    isChecked={showInCheckout}
                    setIsChecked={() => setShowInCheckout(!showInCheckout)}
                  />
                </div>
                <div className="mt-5 mb-10 font-Poppins">
                  <CheckBox
                    label="Night schedule"
                    isChecked={isNight}
                    setIsChecked={() => setIsNight(!isNight)}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <DetailsForm data={detailsData} />
            </>
          )}
          <div className="pb-4 mt-10">
            <div className="inline-block w-36 mr-6">
              <Button
                loading={loading1}
                action={
                  isEditClicked ? () => saveHandler() : () => editHandler()
                }
                title={isEditClicked ? "Save changes" : "Edit"}
              />
            </div>
            <div className="inline-block w-24">
              <Button
                type="delete"
                loading={loading}
                action={deleteHandler}
                title="Delete"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
