import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import DraftEditor from '../components/DraftEditor';

export default function LearningCenterArticlesDetails(props: any) {

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const { state, dispatch } = useContext(Context);

  const [cookies] = useCookies();
  const [title, setTitle] = useState();
  const [excerpt, setExcerpt] = useState();
  const [description, setDescription] = useState();
  const [featuredImage, setFeaturedImage] = useState("No file Chosen");
  const [publishedAt, setPublishedAt] = useState(null);

  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isEditorShown, setIsEditorShown] = useState(false);

  const [detailsData, setDetailsData] = useState([]);
  const [article, setArticle] = useState();

    useEffect(() => {
        let index = state.learningCenterArticles.findIndex((oneArticle) => {
        if (oneArticle._id == props.id) {
            return true;
        }
    });

    let article = state.learningCenterArticles[index];
    setArticle(article);
    const articleDetails = [
      { name: "Article Title", value: article.title },
      { name: "Article Excerpt", value: article.excerpt },
      { name: "Article Text", action: ()=>setIsEditorShown(true), valueType: "button"},
      { name: "Featured Image", value: article.featuredImage, valueType: "image" },
      { name: "Published at", value: article.publishedAt },
    ];
    setDetailsData(articleDetails);

    // Prefill form:
    setTitle(article.title);
    setExcerpt(article.excerpt);
    setDescription(article.description);
    setFeaturedImage(article.featuredImage);
    setPublishedAt(article.publishedAt);
  }, []);

  const saveHandler = () => {
    const data = {
      title,
      excerpt,
      description,
      featuredImage,
      publishedAt,
    };
    axios({
      method: "post",
      url: "/editarticle/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        Toast.fire({
          title: `Article ${title} has been edited`,
          icon: "success",
        });
        let newAricles = [...state.learningCenterArticles];
        let index = newAricles.findIndex((oneArticle) => {
          if(oneArticle._id == props.id) {
            return true;
          }
        });
        newAricles[index] = {
          ...article,
          ...data,
        };
        dispatch({ type: "learningCenterArticles", learningCenterArticles: newAricles });
        props.backToArticles();
      })
      .catch(() => {
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const editHandler = () => {
    setIsEditClicked(true);
  };

  const deleteHandler = () => {
    axios({
      method: "get",
      url: /deletearticle/ + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async (response: any) => {
        Toast.fire({
          title: `Article "${title}" has been deleted`,
          icon: "success",
        });
        let newArticles = [...state.learningCenterArticles];
        let index = newArticles.findIndex((oneArticle) => {
          if(oneArticle._id == props.id) {
            return true;
          }
        });
        newArticles.splice(index, 1);
        dispatch({ type: "learningCenterArticles", learningCenterArticles: newArticles });
        props.backToArticles();
      })
      .catch((err) => {
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const backToEditArticle = () => {
    setIsEditClicked(true)
    setIsEditorShown(false)
  }

  console.log(isEditorShown)
  console.log(isEditClicked)
  return (
    <>
      <div
        style={{backgroundColor: "#f6f6f6", boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)"}}
        className="pt-4 rounded-3xl relative z-20">
        <div className="flex flex-row pb-3">
          <img 
            onClick={isEditClicked&&isEditorShown ? ()=>backToEditArticle() :  isEditClicked ? ()=>setIsEditClicked(false) : isEditorShown ? ()=>setIsEditorShown(false) : ()=>props.backToArticles()}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
          />
          <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {article?.title}
          </p>
        </div>
        <div className="bg-white px-8">
        {isEditorShown ? <DraftEditor articleId={article._id} backToArticleDetails={()=>setIsEditorShown(false)}/> :
        <>
          {isEditClicked ? (
            <div className="bg-white pt-8">
              <InputWithLabel
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                inputType="text"
                label="Article Title"
                placeholder="Type Title"
              />
              <InputWithLabel
                value={excerpt}
                onChange={(e) => setExcerpt(e.target.value)}
                inputType="textarea"
                label="Article Excerpt"
                placeholder="Type Excerpt"
              />
              <InputWithLabel
                action={(e) => setIsEditorShown(true)}
                inputType="button"
                label="Text"
                btnTitle="SHOW IN EDITOR"
              />
              <InputWithLabel
                inputType="file"
                setUrl={setFeaturedImage}
                label="Featured Image"
              />
            </div>
          ) : (
            <DetailsForm data={detailsData} />
          )}
          </>
        }
        { !isEditorShown ? 
          <div className="pb-4 mt-10">
            <div className="inline-block w-36 mr-6">
              <Button
                action={
                  isEditClicked ? () => saveHandler() : () => editHandler()
                }
                title={isEditClicked ? "Save changes" : "Edit"}
              />
            </div>
            <div className="inline-block w-24">
              <Button
                type="delete"
                action={deleteHandler}
                title="Delete"
              />
            </div>
          </div>
        : null }
        </div>
      </div>
    </>
  );
}
