import React, { useReducer, useEffect } from "react";
import { reducer, initialState, Context } from "./utils/store";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
// import MenuProvider from 'react-flexible-sliding-menu';
import Layout from "./components/Layout";
import "./App.css";
// import SideMenu from "./components/SideMenu";
import AdminPage from "./screens/Admin";
import Login from "./screens/Login";
import { useCookies } from "react-cookie";
import NotFound from "./screens/NotFound";
import axios from "./utils/axios";

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    console.log('cookies => ', cookies);
    if (cookies && cookies.tokenId && typeof cookies.tokenId !== 'undefined' && cookies.tokenId !== 'undefined') {
      dispatch({ type: "isLoggedIn", isLoggedIn: true });
    } else {
      dispatch({ type: "isLoggedIn", isLoggedIn: false });
      removeCookie("id");
      removeCookie("tokenId");
      removeCookie("refreshToken");
    }
  }, [0]);

  const validateAccessToken = () => {
    axios({
      method: "post",
      url: "/validate-token",
      data: {token: cookies.tokenId}
    }).then((response)=>{
      console.log(response)
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <Context.Provider value={{ state, dispatch }}>
      {/* <MenuProvider MenuComponent={SideMenu}> */}
      <Router>
        <Layout>
          {state.isLoggedIn ? (
            <Route exact path="/" component={AdminPage} />
          ) : (
            <Route exact path="/" component={Login} />
          )}
          {/* <Route exact path="/" component={AdminPage} />  */}
        </Layout>
      </Router>

      {/* </MenuProvider> */}
    </Context.Provider>
  );
}

export default App;
