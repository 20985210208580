import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import CheckBox from "../components/CheckBox";
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function UserDetails(props: any) {
  const { state, dispatch } = useContext(Context);
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const [cookies] = useCookies();
  const [userDetails, setUserDetails] = useState([]);
  const [usersData, setUsersData] = useState<Array<any> | null>(null);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [userData, setUserData] = useState();
  const [loading1, setLoading1] = useState(false);
  const [oldEmail, setOldEmail] = useState(usersData?.email);

  React.useEffect(() => {
    if (state.users == null) {
      axios.get("/getallusers").then((response) => {
        setUsersData(response.data.users);
        dispatch({ type: "users", users: response.data.users });
      });
    } else {
      setUsersData(state.users);
    }
  }, [state]);

  React.useEffect(() => {
    if (usersData) {
      usersData.forEach((user) => {
        if (user._id == props.id) {
          setUserData(user);
        }
      });
    }
  }, [usersData]);
  
  const phoneData = (value) => {
    return (
      <PhoneInput
        disabled
        country={"us"}
        value={value}
        inputStyle={{ fontSize:'1rem', border: "none", backgroundColor: "transparent" }}
        buttonStyle={{ border: "none", backgroundColor: "transparent" }}
      />
    )
  }
  React.useEffect(() => {
    const details = [
      { name: "Email:", value: userData?.email, editable: true },
      { name: "Phone:", value: phoneData(userData?.phone), editable: true },
      { name: "Address:", value: userData?.address, editable: true },
      { name: "Country:", value: userData?.country, editable: true },
      { name: "City:", value: userData?.city, editable: true },
      { name: "State:", value: userData?.state, editable: true },
      { name: "Zip Code:", value: userData?.zipCode, editable: true },
      {
        name: "Joined Date:",
        value: moment(userData?.joinedDate).format("DD-MM-YYYY"),
      },
      { name: "Stripe Card Token:", value: userData?.stripeCardToken },
      { name: "Active:", value: userData?.isActive == true ? "Yes" : "No" },
      { name: "Blocked:", value: userData?.isBlocked == true ? "Yes" : "No" },
    ];
    setUserDetails(details);
  }, [userData]);

  const backHandler = () => {
    props.backToCauses();
  };
  // console.log(userData)
  const saveHandler = () => {
    setLoading1(true);
    const params = {
      id: props.id,
      email: userData?.email,
      phone: userData?.phone,
      address: userData?.address,
      city: userData?.city,
      state: userData?.state,
      zipCode: userData?.zipCode,
      country: userData?.country,
      isActive : userData?.isActive,
      isBlocked : userData?.isBlocked,
    };
    console.log(params);
    axios({
      method: "post",
      url: "/user/adminedituser",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: params,
    })
      .then(async (response: any) => {
        setLoading1(false);
        console.log(response);
        Toast.fire({
          title: `Updated`,
          icon: "success",
        });

        // props.backToPersonalDate()
        setIsEditClicked(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoading1(false);
        Toast.fire({
          title: "Error",
          icon: "error",
        });
        setIsEditClicked(false);
      });
  };
  const editHandler = () => {
    setIsEditClicked(true);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="flex flex-row pb-3">
          <img
            onClick={backHandler}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
            alt="backarrao"
          />
          <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {userData?.firstName + " " + userData?.lastName}
          </p>
        </div>
        <div className="bg-white px-8">
          {isEditClicked ? (
            <>
              <div className="bg-white px-8 w-full">
                <div className="bg-white grid pt-8">
                  <InputWithLabel
                    value={userData?.email}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                    inputType="text"
                    label="Email"
                    placeholder="Type Email"
                  />
                  <InputWithLabel
                    value={userData?.phone}
                    onChange={(phone) =>
                      setUserData({ ...userData, phone })
                    }
                    inputType="phone"
                    label="Phone"
                    placeholder="Type Phone"
                  />
                   
                  <InputWithLabel
                    value={userData?.address}
                    onChange={(e) =>
                      setUserData({ ...userData, address: e.target.value })
                    }
                    inputType="text"
                    label="Address"
                    placeholder="Type Address"
                  />
                  <InputWithLabel
                    value={userData?.city}
                    onChange={(e) =>
                      setUserData({ ...userData, city: e.target.value })
                    }
                    inputType="text"
                    label="City"
                    placeholder="Type City"
                  />
                  <InputWithLabel
                    value={userData?.state}
                    onChange={(e) =>
                      setUserData({ ...userData, state: e.target.value })
                    }
                    inputType="text"
                    label="State"
                    placeholder="Type State"
                  />
                  <InputWithLabel
                    value={userData?.zipCode}
                    onChange={(e) =>
                      setUserData({ ...userData, zipCode: e.target.value })
                    }
                    inputType="text"
                    label="Zip Code"
                    placeholder="Type zipCode"
                  />
                  <div className="mt-5 mb-5 font-Poppins">
                    <CheckBox
                      label="Active"
                      isChecked={userData?.isActive}
                      setIsChecked={(e) =>
                        setUserData({ ...userData, isActive: e })
                      }
                    />
                  </div>
                  <div className="mt-5 mb-10 font-Poppins">
                    <CheckBox
                      label="Blocked"
                      isChecked={userData?.isBlocked}
                      setIsChecked={(e) =>
                        setUserData({ ...userData, isBlocked: e })
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <DetailsForm data={userDetails} />
          )}

          <div className="pb-4 mt-10">
            <div className="flex flex-row inline-block">
              <div className="mr-2 w-56">
                <Button
                  loading={loading1}
                  action={
                    isEditClicked ? () => saveHandler() : () => editHandler()
                  }
                  title={isEditClicked ? "Save changes" : "Edit"}
                />
              </div>
              <div className="w-56">
                {isEditClicked && (
                  <Button
                    type="delete"
                    action={() => setIsEditClicked(false)}
                    title={"Cancel"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
