import React from 'react'
// import Header from '../components/header'
// import Footer from '../components/footer'
import { useHistory } from 'react-router-dom'
// import StickyHeader from '../components/StickyHeader'
export default function Layout(props:any) {
	let history = useHistory()

    return (
        <div style={{backgroundColor:'rgb(246 246 246)'}} className='bg-gray-100 h-screen bg-opacity-80'>
            
            {/* {history.location.pathname.indexOf('/login')==0 || history.location.pathname.indexOf('/profile')==0   ? null : (history.location.pathname.indexOf('/firsttime')==0 || history.location.pathname.indexOf('/thankyou')==0 ? <StickyHeader /> : <Header/>) 
            } */}
                {props.children}

        </div>
    )
}