import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import CheckBox from "../components/CheckBox";

export default function PersonalDayDetails(props: any) {
  const { state, dispatch } = useContext(Context);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const [loading1, setLoading1] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [cookies] = useCookies();
  const [name, setName] = useState();
  const [icon, setIcon] = useState('No file Chosen');
  const [isMultiple, setIsMultiple] = useState();
  const [allowManualEntry, setAllowManualEntry] = useState();
  const [placeholderText, setPlaceholderText] = useState("");


  const [isEditClicked, setIsEditClicked] = useState(false);
  const [detailsData, setDetailsData] = useState([])
  const [personalDay, setPersonalDay] = useState();

  useEffect(() => {
    let index = state.personalDays.findIndex((oneDay) => {
      if (oneDay._id == props.id) {
        return true;
      }
    })
    if (state.personalDays[index]) {
      let personalDay = state.personalDays[index];
      setPersonalDay(personalDay)
      const personalDayDetails = [
        { name: "Title", value: personalDay.name },
        { name: "Organization Logo", value: personalDay.icon, valueType: "image" },
        { name: "Add/Delete option", value: personalDay.isMultiple ? "on" : "off" },
        { name: "Text Box for data input", value: personalDay.allowManualEntry ? "on" : "off" },

      ]
      setDetailsData(personalDayDetails)

      // Prefill form:
      setName(personalDay.name)
      setIsMultiple(personalDay.isMultiple)
      setAllowManualEntry(personalDay.allowManualEntry)
      setIcon(personalDay.icon)
      setPlaceholderText(personalDay?.placeholderText)
    }
  }, [state])

  const saveHandler = () => {
    setLoading(true)
    const data = {
      name,
      icon,
      id: props.id,
      isMultiple,
      allowManualEntry,
      placeholderText,
      manualEntryText: "",
      status: true,
    };
    axios({
      method: "post",
      url: "/editpersonalday",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        setLoading(false)
        Toast.fire({
          title: `Personal Day "${name}" has been edited`,
          icon: "success",
        });
        let newDays = [...state.personalDays]
        newDays = newDays.map((day) => {
          if (day._id == props.id) {
            let updatedDay;
            updatedDay = {
              name,
              icon,
              _id: props.id,
              isMultiple,
              manualEntryText,
              placeholderText,
              allowManualEntry,
              status: data.status,
            }
            return updatedDay;
          } else { return day }
        })
        dispatch({ type: 'personalDays', personalDays: newDays })
        props.backToPersonalDate()
        setIsEditClicked(false)
      })
      .catch(() => {
        setLoading(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        });
        setIsEditClicked(false)
      });
  };

  const editHandler = () => {
    setIsEditClicked(true)
  }

  const backHandler = () => {
    props.backToPersonalDate()
  }

  const deleteHandler = () => {
    setLoading1(true)
    axios({
      method: "get",
      url: "/deletepersonalday/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async (response: any) => {
        setLoading1(false)
        Toast.fire({
          title: `Personal Day "${name}" has been deleted`,
          icon: "success",
        });
        let newDays = [...state.personalDays]
        let index = newDays.findIndex((oneDay) => {
          if (oneDay._id == props.id) {
            return true;
          }
        })
        newDays.splice(index, 1);

        dispatch({ type: 'personalDays', personalDays: newDays })
        props.backToPersonalDate()
      })
      .catch((err) => {
        setLoading1(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="flex flex-row pb-3">
          <img
            onClick={backHandler}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
          />
          {/* <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 "> */}
          <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {personalDay?.name}
          </p>
          {/* </div> */}
        </div>
        <div className="bg-white px-8">
          {isEditClicked ?
            <div className="bg-white pt-8">
              <InputWithLabel
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputType="text"
                label="Personal Day Name"
                placeholder="Type Name"
              />
              <InputWithLabel
                inputType="file"
                setUrl={setIcon}
                label="Personal Day Icon"
              />
              <div className='mt-5 font-Poppins'> <CheckBox setIsChecked={() => (setIsMultiple(!isMultiple))} isChecked={isMultiple} label='Add/Delete option' /></div>
              <div className='my-5 font-Poppins'> <CheckBox setIsChecked={() => (setAllowManualEntry(!allowManualEntry))} isChecked={allowManualEntry} label='Text Box for data input' /></div>
              { allowManualEntry ? 
                  <InputWithLabel
                  value={placeholderText}
                  onChange={(e) => setPlaceholderText(e.target.value)}
                  inputType="text"
                  label="Placeholder text"
                  placeholder="Type placeholder name"
                /> : null
              }
            </div>
            : <DetailsForm data={detailsData} />
          }
          <div className="pb-4 mt-10">
            <div className="inline-block w-36 mr-6"><Button loading={loading} action={isEditClicked ? () => saveHandler() : () => editHandler()} title={isEditClicked ? "Save changes" : "Edit"} /></div>
            <div className="inline-block w-24"><Button loading={loading1} type="delete" action={deleteHandler} title="Delete" /></div>
          </div>
        </div>
      </div>
    </>
  );
}
