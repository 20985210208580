import React from 'react'
import Checkbox from "react-custom-checkbox";
import { FiCheck } from "react-icons/fi";

export default function CheckBox(props: any) {

    return (
      <Checkbox
                icon={<FiCheck color="#FFF" size={14} />}
                name="my-input"
                labelStyle={{marginLeft: 10, color: "#383e40" }}
                checked={props.isChecked}
                onChange={props.setIsChecked}
                disabled={props.disabled}
                borderColor="#707070"
                borderWidth={props.isChecked ? 0 : 2}
                borderRadius={props.isChecked ? 0 : 2}
                style={props.isChecked ? { backgroundColor: '#d0a342', cursor: "pointer"} : { backgroundColor: '#f6f6f6', cursor: "pointer" }}
                containerClassName=""
                label={props.label}
            />
    )
  }


