import React from 'react';

export const initialState = {
	isLoggedIn: false,
	userInfo: null,
	pagesList: null,
	adminCurrentPage: 'Dashboard',
	params: null,
	showLoader: false,
	causes: null,
	charities: null,
	personalDays: null,
	groupDates: null,
	islamicdays: null,
	islamicmonths: null,
	users: null,
	dashboardInfo: null,
	transactions: null,
	learningCenterArticles: null,
};

export const reducer = (state: any, action: any) => {
	const {
		type,
		isLoggedIn,
		userInfo,
		pagesList,
		adminCurrentPage,
		params,
		showLoader,
		causes,
		charities,
		personalDays,
		groupDates,
		islamicdays,
		islamicmonths,
		users,
		transactions,
		dashboardInfo,
		learningCenterArticles
	} = action;

	switch (type) {
		case 'isLoggedIn':
			return { ...state, isLoggedIn };
		case 'userInfo':
			return { ...state, userInfo };
		case 'users':
			return { ...state, users };
		case 'pagesList':
			return { ...state, pagesList };
		case 'profileCurrentPage':
			return { ...state, adminCurrentPage };
		case 'params':
			return { ...state, params };
		case 'showLoader':
			return { ...state, showLoader };
		case 'causes':
			return { ...state, causes };
		case 'islamicdays':
			return { ...state, islamicdays };
		case 'islamicmonths':
			return { ...state, islamicmonths };
		case 'charities':
			return { ...state, charities };
		case 'personalDays':
			return { ...state, personalDays };
		case 'transactions':
			return { ...state, transactions };
		case 'dashboardInfo':
			return { ...state, dashboardInfo };
		case 'groupDates':
			return { ...state, groupDates };
		case 'learningCenterArticles':
			return { ...state, learningCenterArticles };
		default:
			return state;
	}
};

export const Context = React.createContext<any | null>(null);
