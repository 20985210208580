import React, { useContext, useState, useEffect } from "react";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "./Button";
import {
  EditorState,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Swal from "sweetalert2";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

export default function DraftEditor(props: any) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const { state, dispatch } = useContext(Context);
  const [cookies] = useCookies();

  const [convertedText, setConvertedText] = useState("Some default content");
 
  useEffect(() => {
    if (props.articleId !== "newArticle") {
      let articleIndex = state.learningCenterArticles.findIndex(
        (oneArticle:any) => {
          if (oneArticle._id == props.articleId) {
            return true;
          }
        }
      );
      let articleDescription =
        state.learningCenterArticles[articleIndex].description;
      setConvertedText(articleDescription);
    }
  }, [0]);

  const saveHandler = () => {
    let articleIndex = state.learningCenterArticles.findIndex((oneArticle: { _id: any; }) => {
      if (oneArticle._id === props.articleId) return true;
    });

    let articleData = state.learningCenterArticles[articleIndex];
    const data = {
      ...articleData,
      description: convertedText,
    };
    axios({
      method: "post",
      url: "/editarticle/" + props.articleId,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        Toast.fire({
          title: `Text has been edited and saved`,
          icon: "success",
        });
        let newArticles = [...state.learningCenterArticles];

        newArticles[articleIndex] = { ...data };
        dispatch({
          type: "learningCenterArticles",
          learningCenterArticles: newArticles,
        });
        props.backToArticleDetails();
      })
      .catch(() => {
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const addNewText = () => {
    props.setDescription(convertedText);
    props.backToAddArticle();
  };

  const modules = {
		toolbar: [
	      [{ 'font': [] }],
	      [{ 'size': ['small', false, 'large', 'huge'] }],
	      ['bold', 'italic', 'underline'],
	      [{'list': 'ordered'}, {'list': 'bullet'}],
	      [{ 'align': [] }],
	      [{ 'color': [] }, { 'background': [] }],
	      ['clean']
	    ]
	};

  const formats = [
    'font',
    'size',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'color', 'background'
  ];

  return (
    <div className="pt-12">
      <ReactQuill
        theme='snow'
        modules={modules}
        value={convertedText}
        formats={formats}
        onChange={setConvertedText}
        style={{minHeight: '500px'}}
      />
      <div className="pb-4 mt-10">
        <div className="inline-block w-36 mr-6">
          <Button
            action={props.articleId === "newArticle" ? addNewText : saveHandler}
            title="Save changes"
          />
        </div>
      </div>
    </div>
  );
}
