export const defaultDateFormat = (dateStr) => {
    if (dateStr) {
      const dt = new Date(dateStr)
      const date = dt.getDate()
      const month = dt.getMonth() + 1
      const year = dt.getFullYear()
      return `${month > 9 ? month : `0${month}`}/${date > 9 ? date : `0${date}`}/${year}`
    }
    return ""
  }
  
  export const defaultDateFormatUTC = (dateStr) => {
    if (dateStr) {
      const dt = new Date(dateStr)
      const date = dt.getUTCDate()
      const month = dt.getUTCMonth() + 1
      const year = dt.getUTCFullYear()
      return `${month > 9 ? month : `0${month}`}/${date > 9 ? date : `0${date}`}/${year}`
    }
    return ""
  }
  
  export const defaultTimeFormatUTC = (dateStr) => {
    if (dateStr) {
      const dt = new Date(dateStr)
      const hours = dt.getUTCHours()
      const minutes = dt.getUTCMinutes()
      return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`
    }
    return ""
  }
  
  export const getDayFromDateUTC = (dateStr) => {
    if (dateStr) {
      const dt = new Date(dateStr)
      const day = dt.getUTCDay()
      return day
    }
    return ""
  }