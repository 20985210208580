import React, {useState, useEffect} from 'react'
import Table from '../components/Table';
import axios from '../utils/axios'
import {Context} from '../utils/store';
import moment from "moment";
import GroupDatesDetails from './GroupDatesDetails'
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
export default function GroupDates(props:any) {
	const [cookies] = useCookies();
	const Toast = Swal.mixin({
		toast: true,
		position: "top",
		showConfirmButton: false,
		timer: 5000,
	  });
	const initialTableData = [
		{title: 'Group Name', contentType: 'text', data: []},
		{title: 'Lunar Month', contentType: 'text', data: []},
		{title: 'Action', contentType: 'button', buttonName: 'View', buttonType: 'default', action: null},
		{title: 'Status', contentType: 'button', buttonType: 'status', status: [], action: null},
	]
	
	const GroupDateTitleIndex = 0;
	const LunarMonthIndex = 1;
	const ActionIndex = 2;
	const StatusIndex = 3;

	const {state, dispatch} = React.useContext(Context)
	const [monthsData, setMonthsData] = React.useState<Array<any> | null>(null)
	const [isTableShown, setIsTableShown] = React.useState(false)
	const [tableData, setTableData] = React.useState(initialTableData)
	const [islamicMonths, setIslamicMonths] = React.useState()
    useEffect(() => {
		if(state?.groupDates){
			setMonthsData(state?.groupDates)
		} else{
        axios.get('/groupdates')
            .then((response)=>{ 
				setMonthsData(response.data.response)
				dispatch({ type: 'groupDates', groupDates: response.data.response })
            })
            .catch(err=>{
				console.log(err)
			})
		}
	},[state])

	useEffect(() => {
		if(!islamicMonths){
		axios.get('/islamicmonths')
		  .then((response) => {
			setIslamicMonths(response.data.response)
		  })
		  .catch(err => {
			console.log(err)
		  })
		}
	  }, [state])
	
	useEffect(()=>{
    	setTableDataHandler()
    },[monthsData, islamicMonths])

	const setTableDataHandler = () => {
		let grouoDatesTitles = [];
		let monthsNames = [];
		let statuses = [];

		if(monthsData){
			monthsData.forEach((oneMonthData)=>{
				let islamicMonthIndex = islamicMonths?.findIndex((oneMonth)=>{
					if(oneMonth._id==oneMonthData.islamicMonthId) { return true }
				})
				// oneMonthData.islamicMonthId
				grouoDatesTitles.push(oneMonthData.name)
				if(oneMonthData && islamicMonths){
					monthsNames.push(islamicMonths[islamicMonthIndex]?.name) 
				} 
				if(oneMonthData.isActive){
					statuses.push(true)
				}
				else if(!oneMonthData.isActive){
					statuses.push(false)
				}
			})
		}

		let newTableData = [...tableData]
		newTableData[GroupDateTitleIndex].data = grouoDatesTitles; 
		newTableData[LunarMonthIndex].data = monthsNames; 						
		newTableData[ActionIndex].action = viewHandler; 					
		newTableData[StatusIndex].status = statuses; 
		newTableData[StatusIndex].action = statusHandler; 
		setTableData(newTableData)
		setIsTableShown(true)
	}

	const statusHandler = (index) => {
		let newTableData = [...tableData]
		const data = {
			isActive: !newTableData[StatusIndex].status[index],
			id: monthsData[index]._id
		}
		axios({
			method: "post",
			url: "/changegroupdatestatus",
			headers: {
			  Authorization: `Bearer ${cookies.tokenId}`,
			},
			data: data,
		  })
		  .then((response)=>{
			newTableData[StatusIndex].status[index] = !newTableData[StatusIndex].status[index]
			setTableData(newTableData)
			let newGroupDates = [...monthsData];
        	newGroupDates[index].isActive = newTableData[StatusIndex].status[index]
        	dispatch({ type: 'groupDates', groupDates: newGroupDates })
			Toast.fire({
				title: `${response.data.response}`,
				icon: "success",
			  });
		  })
		  .catch((err)=>{
			console.log(err)
		  })
	}

	const [showGroupDetails, setShowGroupDetails] = React.useState(false)
	const [gropupDatesDetailsID, setGropupDatesDetailsID] = useState()
	const [groupName, setGroupName] = React.useState()

	const viewHandler = (index:number) => {
		setShowGroupDetails(true)
		setGroupName(monthsData[index].name)
		setGropupDatesDetailsID(monthsData[index]._id)
	}
	if(showGroupDetails && gropupDatesDetailsID){
		return (<GroupDatesDetails id={gropupDatesDetailsID} name={groupName} backToGroupDates={()=>setShowGroupDetails(false)} />)
	}else{
    return(<>

           <div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className={`pt-4  rounded-3xl   `}>
           <div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
               <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Group Dates</p>
               <button 
         style={{color:'#c7ad1f', boxShadow:' 0 1.5px 5px 0 rgba(56, 66, 115, 0.11)'}}
		 onClick={() => {
			// dispatch({
			//   type: "params",
			//   params: { ...state.params, id: el.id },
			// });
			props.action();
		  }}
           className="focus:outline-none bg-white rounded-3xl text-white text-sm md:text-bas ml-auto font-Poppins lg:w-48 w-full ml-auto h-12 lg:mt-auto lg:mb-auto mb-2 py-3  z-20 relative hover:opacity-80 ">
          Add New
       </button>
        </div>
           {/* Group Dates */}
		   { isTableShown ? <Table tableData={tableData} rows={monthsData?.length}/> : null }
       </div>
    </>)}
}