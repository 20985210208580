import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import CheckBox from '../components/CheckBox'
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import Dropdown from 'react-dropdown';
import '../styles/dropdown.css';
import moment from "moment";
import DatePicker from 'react-modern-calendar-datepicker';
import { format } from "path";
import { isReturnStatement } from "typescript";
import PlusMinusCircle from "../components/PlusMinusCircle";

export default function GroupDatesDetails(props: any) {
  const { state, dispatch } = useContext(Context);
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies] = useCookies();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState();
  const [lunarDateName, setGroupDateName] = React.useState()
  const [monthName, setMonthName] = React.useState()
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [groupDate, setGroupDate] = useState()
  const [detailsData, setDetailsData] = useState([])
  const [islamicDays, setIslamicDays] = useState()
  const [islamicMonths, setIslamicMonths] = useState()
  const [monthsNames, setMonthsNames] = useState()
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [selectedMonthID, setSelectedMonthID] = useState(null)
  const [loading1, setLoading1] = React.useState(false)
  const [availableDays, setAvailableDays] = useState<Array<any> | null>(null)


  useEffect(() => {
    axios.get('/islamicdays')
      .then((response) => {
        setIslamicDays(response.data.response)
        let newAvaliableDays = [];
        response.data.response?.forEach((oneDay)=>{
          if(oneDay.isActive){ 
            newAvaliableDays.push(oneDay) 
          }
        })
        setAvailableDays(newAvaliableDays)
      })
  }, [])

  useEffect(() => {
    axios.get('/islamicmonths')
      .then((response) => {
        setIslamicMonths(response.data.response)
        let names = response.data.response.map((day) => day.name)
        setMonthsNames(names)
      })
      .catch(err => {
        console.log(err)
      })
  }, [state])

  useEffect(() => {
    let index = islamicMonths?.findIndex((oneMonth) => {
      if (oneMonth._id == groupDate.islamicMonthId) {
        return true;
      }
    })
    if (islamicMonths && !selectedMonth) {
      setSelectedMonth(islamicMonths[index].name)
      setSelectedMonthID(islamicMonths[index]._id)
    }
  }, [islamicMonths])

  useEffect(()=>{
    let newAvaliableDays = [];
    islamicDays?.forEach((oneDay)=>{
      if(oneDay.isActive){ 
        newAvaliableDays.push(oneDay) 
      }
    })
    setAvailableDays(newAvaliableDays)
  },[selectedMonth])


  useEffect(() => {
    let index = state.groupDates.findIndex((oneGroupDate) => {
      if (oneGroupDate._id == props.id) {
        return true;
      }
    })
    let groupDate = state.groupDates[index];
    setGroupDate(groupDate)

    let onlyActiveSelectedDays = groupDate?.selectedDays?.filter((oneDate)=>{
      let value;
      availableDays?.forEach((oneDay) => {
         if (oneDay._id == oneDate && oneDay?.isActive){
          value = oneDate;
        }
      })
      if(value) { return true }
    }) 

    let name;
    let selectedDaysNames = onlyActiveSelectedDays.map((oneDayId) => {
      islamicDays?.forEach((oneIslamicDay) => {
        if (oneIslamicDay._id == oneDayId) {
          name = oneIslamicDay.name;
        }
      })
      return name
    })
    const groupDateDetails = [
      { name: "Group Name", value: groupDate.name },
      { name: "Lunar Dates", value: selectedDaysNames, valueType: "arrayOfStrings" },
    ]
    setDetailsData(groupDateDetails)

    // Prefill form:
    setName(groupDate.name)
  }, [islamicDays, availableDays])


  const saveHandler = () => {
    setLoading1(true)
    const data = {
      name: name,
      islamicMonthId: selectedMonthID,
      isActive: true,
      selectedDays: groupDate.selectedDays,
      id: props.id
    }
    axios({
      method: "post",
      url: "/editgroupdates",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data,
    })
      .then(async (response: any) => {
        setLoading1(false)
        Toast.fire({
          title: `Group Date ${name} has been changed`,
          icon: "success",
        });
        let newGroupDates = [...state.groupDates]
        let index = newGroupDates.findIndex((oneGroupDates) => {
          if (oneGroupDates._id == props.id) { return true }
        })
        newGroupDates[index] = { ...groupDate, name: name, islamicMonthId: selectedMonthID }
        dispatch({ type: 'groupDates', groupDates: newGroupDates })
        props.backToGroupDates()
      })
      .catch(() => {
        setLoading1(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const editHandler = () => {
    setIsEditClicked(true)
  }

  const deleteHandler = () => {
    setLoading(true)
    axios({
      method: "get",
      url: "/deletegroupdate/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async (response: any) => {
        setLoading(false)
        Toast.fire({
          title: `Group Date "${props.name}" has been deleted`,
          icon: "success",
        });
        let newGroupDates = [...state.groupDates]
        let index = newGroupDates.findIndex((oneGroupDate) => {
          if (oneGroupDate._id == props.id) {
            return true;
          }
        })
        newGroupDates.splice(index, 1);
        dispatch({ type: 'groupDates', groupDates: newGroupDates })
        props.backToGroupDates()
      })
      .catch((err) => {
        setLoading(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  }

  const addNewLunarDates = (index: number) => {
    let newGroupDates = { ...groupDate }
    newGroupDates.selectedDays.splice(index + 1, 0, newGroupDates.selectedDays[0])
    setGroupDate(newGroupDates)
  }
  const removeLunarMonth = (index: number) => {
    let newGroupDates = { ...groupDate }
    newGroupDates.selectedDays.splice(index, 1)
    setGroupDate(newGroupDates)
  }

  let groupDateDropdowns = null
  if (state && groupDate) {

    let onlyActiveSelectedDays = groupDate?.selectedDays.filter((oneDate)=>{
      let value;
      availableDays?.forEach((oneDay) => {
         if (oneDay._id == oneDate && oneDay?.isActive){
          value = oneDate;
        }
      })
      if(value) { return true }
    })
    if(onlyActiveSelectedDays.length==0) {onlyActiveSelectedDays.push(null)} 

    groupDateDropdowns = onlyActiveSelectedDays?.map((oneDate, index) => {
      let value: string;
      availableDays?.forEach((oneDay) => {
         if (oneDay._id == oneDate && oneDay?.isActive){
            value = oneDay.name 
        } 
      })

      let options = [];
      availableDays?.forEach((oneDay) => {
        if (oneDay?.islamicMonthId == selectedMonthID && oneDay?.isActive) {
          options.push(oneDay.name)
        }
      })

      return (
        <div className="flex flex-row ml-3 w-auto mb-4">
          <Dropdown
            options={options}
            value={value}
            onChange={(e) => {
              let newGroupDateSelectedDays = [...groupDate.selectedDays];
              let selectedIslamicDayIndex = islamicDays?.findIndex((oneDay) => {
                if (oneDay.name == e.value) { return true }
              })
              newGroupDateSelectedDays[index] = islamicDays[selectedIslamicDayIndex]._id

              let newGroupDate = { ...groupDate }
              newGroupDate.selectedDays = newGroupDateSelectedDays;
              setGroupDate(newGroupDate)
            }}
            className='font-Poppins text-sm 2xl:text-base' />
          {options.length > 1 ?
            <div className="inline-block self-center text-center 2xl:ml-5 ml-0 z-20 relative">
              <PlusMinusCircle
                action={() => addNewLunarDates(index)}
                op="+"
                isActive={true}
              />
              <PlusMinusCircle
                action={() => removeLunarMonth(index)}
                op="-"
                isActive={index > 0 ? true : false}
              />
            </div>
            : null}
        </div>
      )
    })
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="flex flex-row pb-3">
          <img
            onClick={props.backToGroupDates}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
          />
          <p className="inline-block text-xl xl:text-2xl lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {props.name}
          </p>
        </div>
        <div className="bg-white px-8">
          {isEditClicked ?
            <div className="bg-white pt-8">
              <InputWithLabel
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputType="text"
                label="Group Date Name"
                placeholder="Type Name"
              />
              <InputWithLabel
                onChange={(e) => {
                  setSelectedMonth(e.value)
                  let index = islamicMonths.findIndex((oneMonth) => {
                    if (oneMonth.name == e.value) { return true }
                  })
                  setSelectedMonthID(islamicMonths[index]._id)
                  let newGroupDate = {...groupDate}
                  newGroupDate.selectedDays = [""];
                  setGroupDate(newGroupDate)
                }}
                inputType="dropdown"
                label="Lunar Month"
                placeholder="Select Month"
                selectedOption={selectedMonth}
                options={monthsNames}
              />
              <div className="flex flex-row">
                <p className="inline-block w-44 font-Poppins 2xl:text-base text-sm">Lunar Dates</p>
                <div className="inline-block">
                  {groupDateDropdowns}
                </div>
              </div>
            </div>
            :
            <div>
              <DetailsForm data={detailsData} />
            </div>
          }
          <div className="pb-4 mt-10">
            <div className="inline-block w-36 mr-6"><Button loading={loading1} action={isEditClicked ? () => saveHandler() : () => editHandler()} title={isEditClicked ? "Save changes" : "Edit"} /></div>
            <div className="inline-block w-24"><Button type="delete" loading={loading} action={deleteHandler} title="Delete" /></div>
          </div>
        </div>
      </div>
    </>
  );
}