import React, { useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import Button from "../components/Button";
import { Context } from "../utils/store";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DraftEditor from "../components/DraftEditor";
import ArrowLeft from "../assets/icons/ArrowLeft.png";

export default function AddLearningCenterArticles(props: any) {
  const { state, dispatch } = useContext(Context);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies] = useCookies();
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [description, setDescription] = useState("");
  const [featuredImage, setFeaturedImage] = useState("No file Chosen");

  const [isEditorShown, setIsEditorShown] = useState(false);

  const [detailsData, setDetailsData] = useState([]);
  const [article, setArticle] = useState();

  const addNow = () => {
    let publishedAt;
    let day = ("0" + new Date().getDate().toString()).slice(-2);
    let month = ("0" + new Date().getMonth().toString()).slice(-2);
    let year = new Date().getFullYear();
    publishedAt = `${month}-${day}-${year}`;

    const data = {
      title,
      excerpt,
      description,
      featuredImage,
      publishedAt,
      isPublished: true,
    };
    console.log(data);
    axios({
      method: "post",
      url: "/learning-center",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        Toast.fire({
          title: `Article ${title} has been added`,
          icon: "success",
        });
        setTitle("");
        setExcerpt("");
        setDescription("");
        setFeaturedImage(null);

        let newArticles = [...state.learningCenterArticles];
        newArticles.push({ ...response.data.response });
        dispatch({
          type: "learningCenterArticles",
          learningCenterArticles: newArticles,
        });
        props.backToArticles();
      })
      .catch(() => {
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
          <img
            onClick={() => props.backToArticles()}
            className="my-auto lg:mr-5 mr-0"
            src={ArrowLeft}
            alt="articleimage"
          />
          <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            Add Article
          </p>
        </div>
        {isEditorShown ? (
          <div className="bg-white px-8">
            <DraftEditor
              articleId="newArticle"
              setDescription={setDescription}
              descritpion={description}
              backToAddArticle={() => setIsEditorShown(false)}
            />
          </div>
        ) : (
          <div className="bg-white px-8">
            <div className="bg-white pt-8">
              <InputWithLabel
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                inputType="text"
                label="Article Title"
                placeholder="Type Title"
              />
              <InputWithLabel
                value={excerpt}
                onChange={(e) => setExcerpt(e.target.value)}
                inputType="text"
                label="Article Excerpt"
                placeholder="Type Excerpt"
              />
              <InputWithLabel
                action={() => setIsEditorShown(true)}
                inputType="button"
                label="Text"
                btnTitle="SHOW IN EDITOR"
              />
              <InputWithLabel
                inputType="file"
                setUrl={setFeaturedImage}
                label="Featured Image"
              />
            </div>
            <div className="md:w-40 pb-4 mt-10">
              <Button action={addNow} title="Add Now" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
