import React, { useState, useContext, useEffect } from "react";
import { Context } from "../utils/store";
import axios from '../utils/axios'
import { AiOutlinePlus } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import LunarMonthDetails from './LunarMonthDetails'
import Swal from "sweetalert2";
export default function LunarMonths(props: any) {
	const [monthsData, setmonthsData] = useState<Array<any> | null>(null)
	const { state, dispatch } = React.useContext(Context)
	useEffect(() => {
		if (state.islamicmonths == null) {
			axios.get('/islamicmonths')
			.then((response) => {
				let newMonthsData = [...response.data.response]
				newMonthsData.sort((a, b)=>{
					return a.monthNumber-b.monthNumber;
				})
				setmonthsData(newMonthsData)
				dispatch({ type: 'islamicmonths', islamicmonths: newMonthsData })
			})
		}
		else {
			let newMonthsData = [...state.islamicmonths]
				newMonthsData.sort((a, b)=>{
					return a.monthNumber-b.monthNumber;
				})
			setmonthsData(state.islamicmonths)
		}
	}, [state])
	const [cookies] = useCookies();
	const [detailsView, setDetailsView] = React.useState(false)
	const [name, setName] = React.useState()
	const [id, setId] = React.useState()
	const [gradient, setGradient] = React.useState()
	const [status, setStatus] = React.useState()
	const Toast = Swal.mixin({
		toast: true,
		position: "top",
		showConfirmButton: false,
		timer: 5000,
	  });
	let monthBlocks = null;
	const [number, setNumber] = React.useState()
	if (monthsData?.length > 0) {
		monthBlocks = monthsData.map((monthData, index) => {
			return (<MonthBlock
				monthData={monthData}
				activeName={monthData.isActive == true ? 'Active' : 'Inactive'}
				monthIndex={monthData?.monthNumber}
				name={monthData.name}
				activeClick={() => {
					let newMonthData = [...monthsData]
					const data = {
						isActive: !newMonthData[index].isActive,
					}
					axios({
						method: "post",
						url: `/changeislamicmonthstatus/${monthData._id}`,
						headers: {
						  Authorization: `Bearer ${cookies.tokenId}`,
						},
								data: data,
					  })
						.then((response) => {
							newMonthData[index].isActive = !newMonthData[index].isActive
							setmonthsData(newMonthData)
							dispatch({ type: 'islamicmonths', islamicmonths: newMonthData })
							Toast.fire({
								title: `${response.data.resp}`,
								icon: "success",
							  });
						})
				}
				}
				action={() => {
					setStatus(monthData.isActive)
					setGradient(monthData.gradient)
					setId(monthData._id)
					setName(monthData.name)
					setDetailsView(true)
					setNumber(monthData.monthNumber)
				}}
			/>)
		})
	}

	if (detailsView) {
		return (<LunarMonthDetails name={name} monthNumber={number} gradient={gradient} status={status} id={id} backToCauses={() => setDetailsView(false)} />)
	} else {
		return (
			<div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className={`pt-4  rounded-3xl   `}>
				<div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
					<p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Lunar Months</p>
				</div>
				{/* Lunar Months */}
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6 2xl:pr-16 p-8 relative bg-white z-20">
					<div
						onClick={() => {
							props.action();
						}}
						className="bg-white h-52 rounded-xl">
						<div className="grid grid-cols-1 border-dashed border-2 border-darkGrey  text-center h-full rounded-xl">
							<div className="cursor-pointer place-self-center hover:opacity-80 py-10">
								<span className="inline-block rounded-full border-dashed border-2 border-darkGrey p-2"><AiOutlinePlus size="25" color="#C9C9C9" /></span>
								<p className="text-lg font-base mt-4">Add New<br /> Lunar Month</p>
							</div>
						</div>
					</div>
					{monthBlocks}
				</div>
			</div>
		)
	}
}
const MonthBlock = (props: any) => {
	const [menuToggle, setMenuToggle] = useState(false)
	const [menuItems, setMenuItems] = useState([])
	const [active, setActive] = React.useState(false)
	const wrapperRef = React.useRef(null);
	useOutsideAlerter(wrapperRef);
	function useOutsideAlerter(ref) {
		React.useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setMenuToggle(false)
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {

				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	useEffect(() => {
		const items = [{ name: "Active", isShown: true }, { name: "Inactive", isShown: false }, { name: "Edit", isClicked: true },]
		setMenuItems(items)
	}, [])


	const menuHandler = () => {
		setMenuToggle(!menuToggle)
	}

	let menuItemBlocks = null;
	if (menuItems) {
		menuItemBlocks = menuItems.map((item) => {
			if (item.isShown) {
				return <button onClick={props.activeClick} className="p-2 hover:bg-grey w-full text-left focus:outline-none">{props.activeName}</button>
			}
			else if (item.isClicked) {
				return <button onClick={props.action} className="p-2 hover:bg-grey w-full text-left focus:outline-none">{item.name}</button>
			}
		})
	}

	return (
		<div className="bg-white border-2 h-52 rounded-lg px-4">
			<div className="grid grid-cols-12 w-full pt-4">
				<span className="col-span-1">{props.monthIndex}</span>
				<p className='col-span-10 place-self-center text-brownGrey font-Poppins'>{props.name}</p>
				<span ref={wrapperRef} onClick={menuHandler} className="col-span-1 cursor-pointer mt-1 relative">
					<BsThreeDotsVertical />
					{menuToggle ?
						<div className="absolute right-4 top-5 bg-black w-44 h-auto bg-lightGrey">
							{menuItemBlocks}
						</div>
						: null
					}
				</span>
			</div>
		</div>
	)
}