import React, { useEffect } from "react";
import Table from "../components/Table";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import { useCookies } from "react-cookie";
import moment from "moment";
import Swal from "sweetalert2";
import LunarDatesDetails from "./LunarDatesDetails";
import Paginator from "../components/Paginator";
import { defaultDateFormatUTC } from "../utils/date-unitls";
export default function LunarDates(props: any) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const [cookies] = useCookies();
  const initialTableData = [
    { title: "Lunar Dates", contentType: "text", data: [] },
    { title: "Lunar Months", contentType: "text", data: [] },
    { title: "Notification Date", contentType: "text", data: [] },
    {
      title: "Action",
      contentType: "button",
      buttonName: "View",
      buttonType: "default",
      action: null,
    },
    {
      title: "Status",
      contentType: "button",
      buttonType: "status",
      status: [],
      action: null,
    },
  ];

  const CauseTitleIndex = 0;
  const OrganizationIndex = 1;
  const NotificationDateIndex = 2;
  const ActionIndex = 3;
  const StatusIndex = 4;

  const [usersData, setUsersData] = React.useState<Array<any> | null>(null);
  const { state, dispatch } = React.useContext(Context);
  const [daysData, setDaysData] = React.useState<Array<any> | null>(null);
  const [monthsData, setMonthsData] = React.useState<Array<any> | null>(null);
  const [isTableShown, setIsTableShown] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(true);
  const [tableData, setTableData] = React.useState(initialTableData);
  const [showLunarDetails, setShowLunarDetails] = React.useState(false);
  const [lunarId, setLunarId] = React.useState();
  const [lunarName, setLunarName] = React.useState();

  useEffect(() => {
    if (state?.islamicdays) {
      setDaysData(state.islamicdays);
    } else {
      axios
        .get("/islamicdays")
        .then((response) => {
          let newIslamicDays = [...response.data.response];
          newIslamicDays = newIslamicDays.map((oneIslamicDay) => {
            let newCalendarDate = defaultDateFormatUTC(oneIslamicDay.calendarDate);
            let newNotificationDate = defaultDateFormatUTC(
              oneIslamicDay.notificationDate
            );
            return {
              ...oneIslamicDay,
              calendarDate: newCalendarDate,
              notificationDate: newNotificationDate,
            };
          });
          setDaysData(newIslamicDays);
          dispatch({ type: "islamicdays", islamicdays: newIslamicDays });
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }
  }, []);

  const statusHandler = (index) => {
    let newTableData = [...tableData];
    const data = {
      isActive: !newTableData[StatusIndex].status[index],
    };
    axios({
      method: "post",
      url: `/changeislamicdaystatus/${daysData[index]._id}`,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    }).then((response) => {
      newTableData[StatusIndex].status[index] =
        !newTableData[StatusIndex].status[index];
      setTableData(newTableData);
      let newLunarDate = [...daysData];
      newLunarDate[index].isActive = newTableData[StatusIndex].status[index];
      dispatch({ type: "islamicdays", islamicdays: newLunarDate });
      Toast.fire({
        title: `${response.data.resp}`,
        icon: "success",
      });
    });
  };

  React.useEffect(() => {
    if (state?.islamicmonths) {
      setMonthsData(state?.islamicmonths);
    } else {
      axios
        .get("/islamicmonths")
        .then((response) => {
          setMonthsData(response.data.response);
          dispatch({
            type: "islamicmonths",
            islamicmonths: response.data.response,
          });
        })
        .catch((err) => {
          setShowLoader(false);
        });
    }
  }, [state]);

  React.useEffect(() => {
    setTableDataHandler();
  }, [daysData, monthsData]);

  const setTableDataHandler = () => {
    let lunarTitle = [];
    let monthTitle = [];
    let notificationTitle = [];
    let lunarStatus = [];

    if (daysData && monthsData) {
      daysData.forEach((oneDay) => {
        if (oneDay.isActive) {
          lunarStatus.push(true);
        } else if (!oneDay.isActive) {
          lunarStatus.push(false);
        }
        monthsData.forEach((oneCharity) => {
          if (oneDay.islamicMonthId == oneCharity._id) {
            monthTitle.push(oneCharity.name);
          }
        });
        lunarTitle.push(oneDay.name);
        if (oneDay.notificationDate.length > 10) {
          let [year, month, day] = oneDay.notificationDate
            .substring(0, 10)
            .split("-");
          let notificationDate = `${month}/${day}/${year}`;
          notificationTitle.push(notificationDate);
        } else {
          notificationTitle.push(oneDay.notificationDate);
        }
      });
    }

    let newTableData = [...tableData];
    newTableData[CauseTitleIndex].data = lunarTitle;
    newTableData[OrganizationIndex].data = monthTitle;
    newTableData[NotificationDateIndex].data = notificationTitle;
    newTableData[ActionIndex].action = viewHandler;
    newTableData[StatusIndex].action = statusHandler;
    newTableData[StatusIndex].status = lunarStatus;

    setTableData(newTableData);
    setIsTableShown(true);
  };

  const [lunarStatus, setLunarStatus] = React.useState();
  const [pickCalendar, setPickCalendar] = React.useState();
  const [notification, setNotification] = React.useState();
  const [dayId, setDayId] = React.useState();
  const viewHandler = (index: number) => {
    setShowLunarDetails(true);
    setLunarName(daysData[index].name);
    setNotification(daysData[index].notificationDate);
    setPickCalendar(daysData[index].calendarDate);
    setDayId(daysData[index]._id);
    setLunarId(daysData[index].islamicMonthId);
    setLunarStatus(daysData[index].isActive);
  };
  if (showLunarDetails) {
    return (
      <LunarDatesDetails
        id={lunarId}
        dayId={dayId}
        name={lunarName}
        status={lunarStatus}
        notification={notification}
        calendar={pickCalendar}
        backToLunarDates={() => setShowLunarDetails(false)}
      />
    );
  } else {
    return (
      <>
        <div
          style={{
            backgroundColor: "#f6f6f6",
            boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
          }}
          className={`pt-4  rounded-3xl   `}
        >
          <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
            <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
              Lunar Dates
            </p>
            <button
              onClick={() => {
                // dispatch({
                //   type: "params",
                //   params: { ...state.params, id: el.id },
                // });
                props.action();
              }}
              style={{
                color: "#c7ad1f",
                boxShadow: " 0 1.5px 5px 0 rgba(56, 66, 115, 0.11)",
              }}
              className="focus:outline-none bg-white rounded-3xl text-white text-sm md:text-bas ml-auto font-Poppins lg:w-48 w-full ml-auto h-12 lg:mt-auto lg:mb-auto mb-2 py-3  z-20 relative hover:opacity-80 "
            >
              Add New
            </button>
          </div>
          {/* Lunar Dates */}
          {isTableShown ? (
            <>
              <Table tableData={tableData} rows={daysData?.length} />
              <Paginator page={1} />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
