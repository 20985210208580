import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Context } from "../utils/store";
import Swal from "sweetalert2";
import Table from "../components/Table";
import axios from "../utils/axios";
import UserDetails from "./UserDetails";
import Paginator from "../components/Paginator";
export default function User() {
  const { state, dispatch } = React.useContext(Context);
  const initialTableData = [
    { title: "User Name", contentType: "text", data: [] },
    { title: "Email", contentType: "text", data: [] },
    {
      title: "Password",
      contentType: "button",
      buttonName: "Reset",
      buttonType: "default",
      action: null,
    },
    {
      title: "Action",
      contentType: "button",
      buttonName: "View",
      buttonType: "default",
      action: null,
    },
  ];
  const userName = 0;
  const Email = 1;
  const Password = 2;
  const Proxy = 3;

  const [usersData, setUsersData] = useState<Array<any> | null>(null);
  const [isTableShown, setIsTableShown] = React.useState(false);
  const [tableData, setTableData] = React.useState(initialTableData);
  const [cookies] = useCookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [page, setPage] = useState(1);
  const limit = 25;
  const debounceTimeout = 1000;
  const [keyword, setKeyword] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTimeoutObj, setSearchTimeoutObj] = useState(null);
  const [loader, setLoader] = useState(false);

  const handlePaginationButtonClick = (direction, prevPage, nextPage) => {
    setPage(nextPage);
  };
  
  const getAllUsers = async (payload = null) => {
    setLoader(true);
    const params = { page, limit, keyword, ...payload };
    await axios.get("/getallusers", { params }).then((response) => {
      const {
        users: { docs, totalRecords, totalPages },
      } = response.data;
     
      setUsersData(docs);
      setTotalRecords(totalRecords);
      setTotalPages(totalPages);
      dispatch({ type: "users", users: docs });
    });
    setLoader(false);
  };

  React.useEffect(() => {
    
    getAllUsers();
    
  }, []);

  const handlePaginationDebounce = (page) => {
    getAllUsers({ page });
  };

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    setKeyword(value);
    if (searchTimeoutObj) {
      clearTimeout(searchTimeoutObj);
    }
    const obj = setTimeout(() => {
      getAllUsers({ keyword: value, page: 1 });
      setPage(1);
    }, debounceTimeout);
    setSearchTimeoutObj(obj);
  };

  const resetPasswordHandler = async (index: any) => {
    // setLoading(true)
    // console.log("user email", usersData[index].email);
    await axios({
      method: "post",
      url: "/adminresetpassword",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: { email: usersData[index].email },
    })
      .then((response) => {
        Toast.fire({
          title: `Password reset verification email has been sent to ${usersData[index].email}`,
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err.response);
        Toast.fire({
          title:
            err.response.data.message === "Error"
              ? err.response.data.response
              : "Unable to reset password for the user",
          icon: "error",
        });
      });
  };

  React.useEffect(() => {
    setTableDataHandler();
  }, [usersData]);

  let users = null;
  if (usersData) {
    users = usersData.map(() => {
      return null;
    });
  }
  let isUserSelectedArray = [];
  if (usersData) {
    isUserSelectedArray = usersData.map((user: any) => user.isSelected);
  }
  const setTableDataHandler = () => {
    let user = [];
    let email = [];
    let pass = [];
    let proxy = [];

    if (usersData) {
      usersData.forEach((oneUser) => {
        if (!oneUser.firstName || !oneUser.lastName) {
          user.push(oneUser.name);
        } else {
          user.push(oneUser.firstName + " " + oneUser.lastName);
        }
        email.push(oneUser.email);
      });
    }

    let newTableData = [...tableData];
    newTableData[userName].data = user; // Cause Title
    newTableData[Email].data = email; // Organization
    newTableData[Password].action = resetPasswordHandler; // Zakat Eligibility
    newTableData[Proxy].action = viewHandler; // Action
    setTableData(newTableData);
    setIsTableShown(true);
  };
  const [currentUserName, setCurrentUserName] = React.useState();
  const viewHandler = (index: number) => {
    setUserDetailsView(true);
    setCurrentUserName(usersData[index]._id);
  };
  const [userDetailsView, setUserDetailsView] = React.useState(false);

  const handlePageNavClick = (direction, prevPage, nextPage) => {
    console.log(
      "direction, prevPage, nextPage => ",
      direction,
      prevPage,
      nextPage
    );
  };

  if (userDetailsView) {
    return (
      <UserDetails
        id={currentUserName}
        backToCauses={() => setUserDetailsView(false)}
      />
    );
  } else {
    return (
      <>
        <div
          style={{
            backgroundColor: "#f6f6f6",
            boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
          }}
          className={`pt-4  rounded-3xl   `}
        >
          <div className="grid 2xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
            <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
              Users
            </p>
            <div className="flex flex-row  rounded-3xl lg:ml-auto   2xl:mr-0 flex-grow border-none lg:max-w-xs w-full mb-auto mb-5 2xl:mb-0">
              <input
                style={{ backgroundColor: "#fff" }}
                className={`mt-auto mb-auto focus:ring-gray-200  w-full focus:outline-none font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block border-none  pl-5 pr-5 sm:text-sm text-xs 2xl:text-base  rounded-3xl  h-12 z-20 relative  `}
                placeholder="Search Name or Email"
                onChange={handleSearch}
              />
            </div>
          </div>
          {isTableShown ? (
            <>
              {
                loader ? <div className="loader"></div> : <Table tableData={tableData} rows={usersData?.length} />
              } 
            </>
          ) : (
            <p className="p-4 text-sm">No users found!</p>
          )}
          <Paginator
            page={page}
            limit={limit}
            totalRecords={totalRecords}
            totalPages={totalPages}
            handleClick={handlePaginationButtonClick}
            debounceCallback={handlePaginationDebounce}
            timeout={debounceTimeout}
          />
        </div>
      </>
    );
  }
}
