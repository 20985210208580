import React, { useState, useEffect, useContext } from "react";
import { Context } from "../utils/store";
import axios from "../utils/axios";
import Table from "../components/Table";
import moment from "moment";
import { useCookies } from "react-cookie";
import OrganizationsDetails from "./OrganizationsDetails";
import Swal from "sweetalert2";
import Paginator from "../components/Paginator";
export default function Organization(props: any) {
  const { state, dispatch } = useContext(Context);
  const [cookies] = useCookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  const OrganizationIndex = 0;
  const dateIndex = 1;
  const ActionIndex = 2;
  const StatusIndex = 3;

  const initialTableData = [
    {
      title: "Organization Name",
      contentType: "text",
      data: ["ICNA Relief", "ICNA Relief", "ICNA Relief", "ICNA Relief"],
    },
    {
      title: "Date",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Action",
      contentType: "button",
      buttonName: "View",
      buttonType: "default",
      action: null,
    },
    {
      title: "Status",
      contentType: "button",
      buttonType: "status",
      status: null,
      action: null,
    },
  ];

  const [tableData, setTableData] = useState(initialTableData);
  const [charities, setCharities] = useState();
  const [isTableShown, setIsTableShown] = useState(false);

  useEffect(() => {
    if (state?.charities) {
      setCharities(state.charities);
    } else {
      axios
        .get("/charities")
        .then((response) => {
          setCharities(response.data.charities);
          dispatch({ type: "charities", charities: response.data.charities });
        })
        .catch((err) => console.log(err));
    }
  }, [state]);

  useEffect(() => {
    setTableDataHandler();
  }, [charities]);

  const setTableDataHandler = () => {
    let organizationsTitles = [];
    let dates = [];
    let statuses = [];

    if (charities) {
      charities.forEach((oneCharitie) => {
        organizationsTitles.push(oneCharitie.name);
        if (oneCharitie?.addedOn) {
          dates.push(moment(oneCharitie.addedOn).format("DD-MM-YYYY"));
        } else {
          dates.push("");
        }

        if (oneCharitie.isActive) {
          statuses.push(true);
        } else if (!oneCharitie.isActive) {
          statuses.push(false);
        }
      });
    }

    let newTableData = [...tableData];
    newTableData[OrganizationIndex].data = organizationsTitles;
    newTableData[dateIndex].data = dates;
    newTableData[ActionIndex].action = viewHandler;
    newTableData[StatusIndex].status = statuses;
    newTableData[StatusIndex].action = statusHandler;
    setTableData(newTableData);
    setIsTableShown(true);
  };

  const [showOrganizationDetails, setShowOrganizationDetails] = useState(false);
  const [organizationDetailsID, serOrganizationDetailsID] = useState(null);

  const viewHandler = (index: number) => {
    setShowOrganizationDetails(true);
    serOrganizationDetailsID(charities[index]._id);
  };

  const statusHandler = (index) => {
    let newTableData = [...tableData];
    const data = {
      isActive: !newTableData[StatusIndex].status[index],
      id: charities[index]._id,
    };
    axios({
      method: "post",
      url: "/changecharitystatus",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    }).then((response) => {
      newTableData[StatusIndex].status[index] =
        !newTableData[StatusIndex].status[index];
      setTableData(newTableData);
      let newCharities = [...charities];
      newCharities[index].status = newTableData[StatusIndex].status[index];
      dispatch({ type: "charities", personalDays: newCharities });
      Toast.fire({
        title: `${response.data.response}`,
        icon: "success",
      });
    });
  };

  if (showOrganizationDetails && organizationDetailsID) {
    return (
      <OrganizationsDetails
        id={organizationDetailsID}
        backToOrganzations={() => setShowOrganizationDetails(false)}
      />
    );
  } else {
    return (
      <>
        <div
          style={{
            backgroundColor: "#f6f6f6",
            boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
          }}
          className={`pt-4  rounded-3xl   `}
        >
          <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
            <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
              Organizations
            </p>
            <button
              onClick={() => {
                // dispatch({
                //   type: "params",
                //   params: { ...state.params, id: el.id },
                // });
                props.action();
              }}
              style={{
                color: "#c7ad1f",
                boxShadow: " 0 1.5px 5px 0 rgba(56, 66, 115, 0.11)",
              }}
              className="focus:outline-none bg-white rounded-3xl text-white text-sm md:text-bas ml-auto font-Poppins lg:w-48 w-full ml-auto h-12 lg:mt-auto lg:mb-auto mb-2 py-3  z-20 relative hover:opacity-80 "
            >
              Add New
            </button>
            {/* <div className='flex flex-row  rounded-3xl lg:ml-auto   2xl:mr-0 flex-grow border-none lg:max-w-250 w-full mb-auto mb-5 2xl:mb-0'> */}
            <input
              style={{ backgroundColor: "#fff" }}
              className={`mt-auto mb-auto rounded-3xl 2xl:ml-12 lg:ml-3  2xl:mr-0 flex-grow border-none lg:max-w-250 mb-auto mb-5 2xl:mb-0 focus:ring-gray-200  w-full focus:outline-none font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block border-none  pl-5 pr-5 sm:text-sm text-xs 2xl:text-base  rounded-xl  h-12 z-20 relative  `}
              placeholder="Search"
            />
            {/* </div> */}
          </div>
          {/* Organizations */}
          {isTableShown ? (
            <>
              <Table tableData={tableData} rows={charities?.length} />
              <Paginator page={1} />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
