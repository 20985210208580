import React, {useState, useEffect, useContext} from 'react'
import { Context } from "../utils/store";
import axios from '../utils/axios'
import Table from '../components/Table';
import CauseDetails from './CauseDetails';
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
export default function Cause(props:any) {
	const { state, dispatch } = useContext(Context);
	const [cookies] = useCookies();
	const Toast = Swal.mixin({
		toast: true,
		position: "top",
		showConfirmButton: false,
		timer: 5000,
	  });
	const CauseTitleIndex = 0;
	const OrganizationIndex = 1;
	const ZakatIndex = 2;
	const ActionIndex = 3;
	const StatusIndex = 4;

	const initialTableData = [
		{title: 'Cause Title', contentType: 'text', data: []},
		{title: 'Organization', contentType: 'text', data: []},
		{title: 'Zakat Eligibility', contentType: 'text', data: []},
		{title: 'Action', contentType: 'button', buttonName: 'View', buttonType: 'default', action: null},
		{title: 'Status', contentType: 'button', buttonType: 'status', status: [], action: null},
	]

	const [tableData, setTableData] = useState(initialTableData)
    const [causes, setCauses] = useState()
    const [charities, setCharities] = useState()
    const [isTableShown, setIsTableShown] = useState(false)

    useEffect(()=>{
		if(state?.causes){
			setCauses(state.causes)
		} else{
	        axios.get('/causes')
	            .then((response)=>{
					setCauses(response.data.response)
	            	dispatch({ type: 'causes', causes: response.data.response })
	            })
				.catch(err=>console.log(err))
			}
    },[state])

    useEffect(()=>{
		if(state?.charities){
			setCharities(state.charities)
		} else {
	        axios.get('/charities')
	            .then((response)=>{
	                    dispatch({ type: 'charities', charities: response.data.charities })
	                    setCharities(response.data.charities)
	            })
				.catch(err=>console.log(err))
		}
    },[state])

    useEffect(()=>{
    	setTableDataHandler()
    },[charities, causes])


	const setTableDataHandler = () => {

		let causesTitles = [];
		let organizationsTitles = [];
		let zakats = [];
		let causeStatuses = [];

		if(causes && charities){
			causes.forEach((oneCause)=>{
				if(oneCause.isActive){
					causeStatuses.push(true)
				}
				else if(!oneCause.isActive){
					causeStatuses.push(false)
				}
				causesTitles.push(oneCause.name)
				charities.forEach((oneCharity)=>{
					if(oneCharity._id==oneCause.parentCharity){
						organizationsTitles.push(oneCharity.name)
					}
				})
				if(oneCause.isZakat){
					zakats.push("Yes")	
				}
				else if(!oneCause.isZakat){
					zakats.push("No")
				}
			})
		}

		let newTableData = [...tableData]
		newTableData[CauseTitleIndex].data = causesTitles; 			// Cause Title
		newTableData[OrganizationIndex].data = organizationsTitles; // Organization
		newTableData[ZakatIndex].data = zakats; 					// Zakat Eligibility
		newTableData[ActionIndex].action = viewHandler; 			// Action
		newTableData[StatusIndex].action = statusHandler; 			// Status
		newTableData[StatusIndex].status = causeStatuses; 

		setTableData(newTableData)
		setIsTableShown(true)
	}

	const statusHandler = (index) => {
		let newTableData = [...tableData]
		const data = {
			isActive: !newTableData[StatusIndex].status[index],
			id: causes[index]._id
		}
		axios({
			method: "post",
			url: "/changecausestatus",
			headers: {
			  Authorization: `Bearer ${cookies.tokenId}`,
			},
			data: data,
		  })
		  .then((response)=>{
			  console.log(response)
			newTableData[StatusIndex].status[index] = !newTableData[StatusIndex].status[index]
			setTableData(newTableData)
			let newCauses = [...causes];
			newCauses[index].isActive = newTableData[StatusIndex].status[index]
        	dispatch({ type: 'causes', causes: newCauses })
			console.log(response)
			Toast.fire({
				title: `Status has been changed successfully`,
				icon: "success",
			  });
		  })
		  
	}

	const [showCauseDetails, setShowCauseDetails] = useState(false)
	const [causeDetailsID, setCauseDetailsID] = useState(null)

	const viewHandler = (index:number) => {
		setShowCauseDetails(true)
		setCauseDetailsID(causes[index]._id)
	}

	if(showCauseDetails && causeDetailsID){
		return (<CauseDetails id={causeDetailsID} backToCauses={()=>setShowCauseDetails(false)} />)
	}else{
		return(
			<>
			<div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className={`pt-4  rounded-3xl   `}>
					<div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
						<p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Causes for donation</p>
						<button 
						onClick={() => {
							// dispatch({
							//   type: "params",
							//   params: { ...state.params, id: el.id },
							// });
							props.action();
						}}
						style={{color:'#c7ad1f', boxShadow:' 0 1.5px 5px 0 rgba(56, 66, 115, 0.11)'}}
						className="focus:outline-none bg-white rounded-3xl text-white text-sm md:text-bas ml-auto font-Poppins lg:w-48 w-full ml-auto h-12 lg:mt-auto lg:mb-auto mb-2 py-3  z-20 relative hover:opacity-80 ">
						Add New
						</button>     
					</div>
					{/* Cause */}
					{ isTableShown ? <Table tableData={tableData} rows={causes?.length}/> : null }
				</div>
			</>
		)
	}
}