import React, { useContext, useState } from "react";
import Bell from "../assets/icons/bell_2x.png";
import logo from "../assets/logo.svg";
import { Context } from "../utils/store";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import axios from "../utils/axios";
import { useCookies } from "react-cookie";

export default function StickyHeader() {
  const { state, dispatch } = useContext(Context);

  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const dropdownHandler = () => {
    setIsDropdownShown(!isDropdownShown);
  };

  const logoutHandler = async () => {
    await axios({
      method: "post",
      url: "/logout",
      data: { email: state.userInfo.email },
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        dispatch({
          type: "userInfo",
          userInfo: null,
        });
      })
      .catch((err) => console.log(err));
    removeCookie("id");
    removeCookie("cookie");
    removeCookie("tokenId");
    removeCookie("refreshToken");
    dispatch({ type: "isLoggedIn", isLoggedIn: false });
    window.location.href = "/";
  };

  return (
    <>
      <div className="md:px-10 w-full mb-3 flex flex-nowrap justify-between">
        <a className="inline-block" href="/">
          {" "}
          <img
            className="md:h-12 h-10 md:ml-8 ml-2 lg:ml-12  mt-4"
            src={logo}
            alt="Logo"
          />{" "}
        </a>
        {state.isLoggedIn ? (
          <div className="inline-block   grid grid-cols-2 float-right mt-4 md:mr-10 lg:mr-12 mr-2">
            <div className="inline-block justify-self-end pt-2 pr-4">
              <img className="inline-block" src={Bell} alt="Bell" />
            </div>
            <div
              onClick={() => dropdownHandler()}
              className="cursor-pointer relative inline-block md:block bg-grey rounded-full pr-4 flex flex-nowrap md:mb-2"
            >
              <a>
                <img
                  className="w-10 inline-block rounded-full mr-2"
                  src={state.userInfo?.userImage}
                />
                <span className="md:text-sm text-xs font-bold mt-auto mb-auto ">
                  {state?.userInfo?.firstName} {state?.userInfo?.lastName}
                </span>
                {!isDropdownShown ? (
                  <FiChevronDown className="ml-2 inline-block" />
                ) : (
                  <FiChevronUp className="ml-2 inline-block" />
                )}
              </a>
              {isDropdownShown ? (
                <div className="absolute -bottom-22 transform translate-y-2 rounded-md right-0 w-full h-auto bg-grey z-30">
                  <span
                    onClick={logoutHandler}
                    className="block p-4 hover:bg-darkGrey"
                  >
                    Log out
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
