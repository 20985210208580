import React, { useState, useEffect, useContext } from 'react'
import Button from '../components/Button'
import CheckBox from '../components/CheckBox';
import PlusMinusCircle from "../components/PlusMinusCircle";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from 'react-modern-calendar-datepicker';
import Swal from "sweetalert2";
import { InputWithLabel } from '../components/Input'
import Dropdown from 'react-dropdown';
import '../styles/dropdown.css';
import axios from '../utils/axios'
import { useCookies } from "react-cookie";
import { Context } from "../utils/store";

import moment from "moment";

export default function AddGroupDates(props:any) {
  const { state, dispatch } = useContext(Context);
  const [cookies] = useCookies();
  const [groupName, setGroupName] = React.useState('')
  const [causeBelongingOptions, setCauseBelongingOptions] = useState()
  const [daysOptions, setDaysOptions] = useState()
  const [monthsData, setmonthsData] = useState<Array<any> | null>(null)

  const [lunarDates, setLunarDates] = useState<Array<any> | null>([]);
  const [monthName, setMonthName] = React.useState('')
  const [monthId, setMonthId] = React.useState()
  const [monthDays, setMonthDays] = React.useState<Array<any> | null>(null)
  const [causeBelongingSelectedOption, setCauseBelongingSelectedOption] = useState()
  const [availableDays, setAvailableDays] = useState<Array<any> | null>(null)
  const [selectMonthDay, setSelectMonthDay] = React.useState()
  const [loading, setLoading] = React.useState(false);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });
  useEffect(() => {
    axios.get('/islamicmonths')
      .then((response) => {
        setmonthsData(response.data.response)

      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get('/islamicdays')
      .then((response) => {
        setMonthDays(response.data.response)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
//Доступные месяцы
  useEffect(() => {
    let newCauseBelongingOptions = monthsData?.map((oneCharity) => oneCharity.name)
    setCauseBelongingOptions(newCauseBelongingOptions)
  }, [monthsData])

//Выбор дня по выбранному месяцу
  useEffect(() => {
    let newDaysOptions = availableDays?.map((day) => day.name)
    setDaysOptions(newDaysOptions)
  }, [availableDays])

  useEffect(() => {
    if (monthsData) {
      monthsData.forEach((oneCause) => {
        if (oneCause.name == monthName) {
          setMonthId(oneCause._id)
        }
      })
    }
  }, [monthName])

  //Доступные дни
  useEffect(() => {
  let days = []
  let newLunarDates = [...lunarDates]
  if(monthDays) {
    monthDays.forEach((oneDay) => {
      if (oneDay.islamicMonthId == monthId) {
        days.push(oneDay)
      }
    })
    if(days.length>0){
      newLunarDates[0] = days[0]._id;
      let newAvaliableDays = [];
      days.forEach((oneDay)=>{
        if(oneDay.isActive){ newAvaliableDays.push(oneDay) }
      })
      setAvailableDays(newAvaliableDays)
      setLunarDates(newLunarDates)
    }
  }
},[monthId])

  const addNewLunarDates = (index: number) => {
    if(daysOptions?.length > index + 1 ) {
      let newLunarDates = [...lunarDates]
      newLunarDates[index+1] = availableDays[index+1]._id;
      setLunarDates(newLunarDates)
    }
  }
  const removeLunarMonth = (index: number) => {
    let newDaysOption = [...lunarDates]
    newDaysOption.splice(index, 1)
    setLunarDates(newDaysOption)
  }
  //Если изменился месяц, дропбокс очищается

  let lunarDateBlocks = null
  lunarDateBlocks = lunarDates?.map((oneDate, index) => {
      return (
        <div className='flex flex-row py-6'>
        <div className="inline-block md:justify-self-end ml-10">
        <Dropdown 
                options={daysOptions} 
                value={daysOptions ? daysOptions[index] : null}
                onChange={(e) => {
                  let newLunarDates = [...lunarDates];
                  let selectedIslamicDayIndex = monthDays?.findIndex((oneDay)=>{
                    if(oneDay.name==e.value) { return true }
                  })
                  newLunarDates[index] = monthDays[selectedIslamicDayIndex]._id
                  setLunarDates(newLunarDates)
                }} 
                className='font-Poppins text-sm 2xl:text-base ' />
        </div>
        {daysOptions?.length == 1  ? (null) : (
      <div className="inline-block self-center text-center 2xl:ml-5 ml-0 z-20 relative">
          <PlusMinusCircle
          action={() => addNewLunarDates(index)}
          op="+"
          isActive={true}
        /> 
        <PlusMinusCircle
          action={() => removeLunarMonth(index)}
          op="-"
          isActive={index > 0 ? true : false}
        />
      </div>
        )}
      </div>
      )
    })

  const addNow = () => {
    setLoading(true)
    const params = {
      name: groupName,
      islamicMonthId: monthId,
      isActive: true,
      selectedDays: lunarDates
    }
    axios({
      method: "post",
      url: "/groupdates",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: params,
    })
      .then(async (response: any) => {
        setLoading(false)
        Toast.fire({
          title: `Group Date ${groupName} has been added`,
          icon: "success",
        });
        let newGroupDates = [...state.groupDates]
        newGroupDates.push({...response.data.response})
        dispatch({ type: 'groupDates', groupDates: newGroupDates })
        props.backToGroupDates()
      })
      .catch((err) =>{
        setLoading(false)
        Toast.fire({
          title: "Error",
          icon: "error",
        })
      });
  }

  return (
    <div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className="pt-4 rounded-3xl z-20 relative">
      <div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
        <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Group Dates</p>
      </div>
      <div className='bg-white px-8   '>
        <div className='bg-white table  '>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Group Name</p>
              <input
                onChange={(e)=>setGroupName(e.target.value)}
                style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}
                className={`mt-auto mb-auto table-cell rounded-md  2xl:mr-0 flex-grow    mb-5 2xl:mb-0 focus:ring-white  w-full  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                placeholder='Type group name'
              />
            </div>
          </div>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Lunar Month</p>
              <div className=' table-cell relative z-40 '>
                <Dropdown 
                  options={causeBelongingOptions} 
                  onChange={(e) => setMonthName(e.value)} 
                  placeholder="Select month" 
                  className='font-Poppins text-sm 2xl:text-base ' />
              </div>
            </div>
          </div>
          {monthId != null ? (
 <div className="table-row-group">
   <div className='table-row flex flex-row'>
     <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Add Lunar dates</p>
    <div className='-ml-10 relative z-20'>
      {lunarDateBlocks}
    </div></div></div>
          ) : null}
          <div className='md:w-40 pb-4 mt-10'> <Button loading={loading} action={addNow} title='Add Now' /></div>
        </div>

      </div>
    </div>
  )
}