import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import CheckBox from '../components/CheckBox'
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import ReactColorPicker from '@super-effective/react-color-picker';
export default function LunarMonthDetails(props: any) {
    const { state, dispatch } = useContext(Context);
    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
    });
    const [cookies] = useCookies();

    const [color1, setColor1] = useState("#3cd6bf")
    const [color2, setColor2] = useState("#3cd6bf")
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);

    const [monthName, setMonthName] = React.useState()
    const [isEditClicked, setIsEditClicked] = useState(false);
    const [detailsData, setDetailsData] = useState([])
    const [monthData, setMonthData] = React.useState()
    const [name, setName] = useState()
    const [status, setStatus] = React.useState()
    const [number, setNumber] = React.useState()
    //Подключение месяцев
    React.useEffect(() => {
        axios.get('/islamicmonths')
            .then((response) => {
                setMonthData(response.data.response)
            })
    }, [])

    React.useEffect(() => {

        const causeDetails = [
            { name: "Lunar Month Title", value: props.name },
            { name: "Color 1", value: props.gradient.boxColor1, box: props.gradient.boxColor1, parametrs: { backgroundColor: props.gradient.boxColor1, width: 30, height: 30 } },
            { name: "Color 2", value: props.gradient.boxColor2, box: props.gradient.boxColor2, parametrs: { backgroundColor: props.gradient.boxColor2, width: 30, height: 30 } },
            { name: "Month Number", value: props.monthNumber },
            { name: "Shown in Checkout", value: props.status == true ? "Yes" : "No" },

        ]
        setDetailsData(causeDetails)
        setColor1(props.gradient.boxColor1)
        setColor2(props.gradient.boxColor2)
        setStatus(props.status)
        setName(props.name)
        setNumber(props.monthNumber)
    }, [monthName])


    const saveHandler = () => {
        setLoading1(true);
        const params = {
            monthNumber:number,
            name: name,
            status: 1,
            isActive: status,
            gradient: { "boxColor1": color1, "boxColor2": color2 },
        }

        axios({
            method: "post",
            url: `/editislamicmonth/${props?.id}`,
            headers: {
                Authorization: `Bearer ${cookies.tokenId}`,
            },
            data: params,
        })
            .then(async (response: any) => {
                Toast.fire({
                    title: `Lunar Month has been edited`,
                    icon: "success",
                });
                let newDays = [...state.islamicmonths]
                newDays = newDays.map((day) => {
                  if (day._id == props.id) {
                    let updatedDay;
                    updatedDay = {
                        _id:props?.id,
                        name: name,
                        monthNumber:number,
                        status: 1,
                        isActive: status,
                        gradient: { "boxColor1": color1, "boxColor2": color2 },
                    }
                    return updatedDay;
                  } else { return day }
                })

				newDays.sort((a, b)=>{
					return a.monthNumber-b.monthNumber;
				})
                dispatch({ type: 'islamicmonths', islamicmonths: newDays })
                props.backToCauses()
                setLoading1(false);
            })
            .catch(() => {
                Toast.fire({
                    title: "Error",
                    icon: "error",
                });
                setLoading1(false);
            });
    };

    const editHandler = () => {
        setIsEditClicked(true)
    }

    const backHandler = () => {
        props.backToCauses()
    }

    const deleteHandler = () => {
        setLoading(true);
        const getSingleTransaction = async () => {
            const response = await axios({
                method: "get",
                url: `/deleteislamicmonth/${props?.id}`,
                headers: {
                    Authorization: `Bearer ${cookies.tokenId}`,
                },
            }).then((response) => {
                setLoading(false);
                Toast.fire({
                    title: `${response.data.response}`,
                    icon: "success",
                });
                let newIslamicMonths = [...state.islamicmonths]
                let index = newIslamicMonths.findIndex((oneDay) => {
                    if (oneDay._id == props.id) {
                        return true;
                    }
                })
                newIslamicMonths.splice(index, 1);
                dispatch({ type: 'islamicmonths', islamicmonths: newIslamicMonths })

            })
                .then(() => {
                    props.backToCauses()
                });
        };
        getSingleTransaction();
    }

    console.log(props.gradient.boxColor2)
    return (
        <>
            <div
                style={{
                    backgroundColor: "#f6f6f6",
                    boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
                }}
                className="pt-4 rounded-3xl relative z-20"
            >
                <div className="flex flex-row pb-3">
                    <img
                        onClick={backHandler}
                        className="my-auto ml-4 lg:mr-5 mr-0"
                        src={ArrowLeft}
                    />
                    <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
                        {props.name}
                    </p>
                </div>
                <div className="bg-white px-8">
                    {isEditClicked ?
                        <>


                            <div className='bg-white px-8 w-full'>
                                <div className='bg-white grid pt-8'>
                                    <InputWithLabel value={name} onChange={(e) => setName(e.target.value)} inputType="text" label="Lunar Month Title" placeholder="Type Lunar Title" />
                                    <div className="grid gap-y-5">
                                        <label className='2xl:text-base text-sm transform translate-y-4'>Color 1</label>
                                        <div className="w-96">
                                            <ReactColorPicker className="w-96" color={color1} onChange={(updatedColor) => setColor1(updatedColor)} />
                                        </div>
                                        <label className='2xl:text-base text-sm transform translate-y-4'>Color 2</label>
                                        <div className="w-96">
                                            <ReactColorPicker color={color2} onChange={(updatedColor) => setColor2(updatedColor)} />
                                        </div>
                                        <label className='2xl:text-base text-sm transform translate-y-4'>Gradient</label>
                                        <div style={{ backgroundImage: `linear-gradient(to right, ${color1}, ${color2})` }} className="w-96 h-12"></div>
                                    </div>
                                    <div className="table-row-group mt-5">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Month Number</p>
              <input
              value={number}
                onChange={(e) => setNumber(e.target.value)}
                type="number"
                style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}
                className={`mt-auto mb-auto table-cell rounded-md w-72 2xl:mr-0 flex-grow    mb-5 2xl:mb-0 focus:ring-white  w-full  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                placeholder='Month Number'
              />
            </div>
          </div> 
                                </div>

                            </div>
                            <div className='mt-5 mb-10 font-Poppins relative z-20'> <CheckBox label='Show in checkout' isChecked={status} setIsChecked={() => setStatus(!status)} /></div>
                        </>
                        :
                        <><DetailsForm data={detailsData} />

                        </>
                    }
                    <div className="pb-4 mt-10">
                        <div className="inline-block w-36 mr-6"><Button loading={loading1} action={isEditClicked ? () => saveHandler() : () => editHandler()} title={isEditClicked ? "Save changes" : "Edit"} /></div>
                        <div className="inline-block w-24"><Button type="delete" loading={loading} action={deleteHandler} title="Delete" /></div>
                    </div>
                </div>
            </div>
        </>
    );
}