import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import Button from "../components/Button";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import DetailsForm from "../components/DetailsForm";
import ArrowLeft from "../assets/icons/ArrowLeft.png";
import CheckBox from "../components/CheckBox";

export default function OrganizationsDetails(props: any) {
  const { state, dispatch } = useContext(Context);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies] = useCookies();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [stripeId, setStripeId] = useState();
  const [selectedLogo, setSelectedLogo] = useState("No file Chosen");
  const [selectedImage, setSelectedImage] = useState("No file Chosen");
  const [logo, setLogo] = useState();
  const [image, setImage] = useState();
  const [startedYear, setStartedYear] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [charity, setCharity] = useState();
  const [certificate, setCertificate] = useState();

  useEffect(() => {
    let index = state.charities.findIndex((oneCharity) => {
      if (oneCharity._id == props.id) {
        return true;
      }
    });
    let charity = state.charities[index];
    setCharity(charity);
    const organizationDetails = [
      { name: "Organization Name", value: charity.name },
      { name: "Organization Logo", value: charity.logo, valueType: "image" },
      { name: "Featured Image", value: charity.image, valueType: "image" },
      { name: "Organization Short Description", value: charity.description },
      { name: "Stripe ID", value: charity.stripeConnectId },
      { name: "Started year", value: charity?.startedYear?.substring(0, 4) },
      { name: "isVerified", value: charity?.isVerified ? "Yes" : "No" },
      { name: "Certificate", value: charity?.certificate },
    ];
    setDetailsData(organizationDetails);

    // Prefill form:
    setName(charity.name);
    setDescription(charity.description);
    setStripeId(charity.stripeConnectId);
    setLogo(charity.logo);
    setImage(charity.image);
    setStartedYear(charity?.startedYear?.substring(0, 4));
    setIsVerified(charity?.isVerified);
    setCertificate(charity?.certificate)
  }, []);

  const saveHandler = () => {
    setLoading(true);
    const data = {
      name,
      logo,
      description,
      image,
      addedOn: new Date(),
      isActive: true,
      stripeId: stripeId,
      startedYear,
      isVerified,
      certificate
    };
    axios({
      method: "post",
      url: "/charities/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        setLoading(false);
        Toast.fire({
          title: `Organization ${name} has been edited`,
          icon: "success",
        });
        let newCharities = [...state.charities];
        let index = newCharities.findIndex((oneCharity) => {
          if (oneCharity._id == props.id) {
            return true;
          }
        });
        newCharities[index] = {
          ...charity,
          description,
          image,
          logo,
          name,
          certificate,
          startedYear,
          stripeId: stripeId,
        };
        dispatch({ type: "charities", charities: newCharities });
        props.backToOrganzations();
      })
      .catch(() => {
        setLoading(false);
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const editHandler = () => {
    setIsEditClicked(true);
  };

  const deleteHandler = () => {
    setLoading1(true);
    axios({
      method: "get",
      url: "/deletecharities/" + props.id,
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then(async (response: any) => {
        setLoading1(false);
        Toast.fire({
          title: `Organization "${name}" has been deleted`,
          icon: "success",
        });
        let newDays = [...state.charities];
        let index = newDays.findIndex((oneCharity) => {
          if (oneCharity._id == props.id) {
            return true;
          }
        });
        newDays.splice(index, 1);
        dispatch({ type: "charities", charities: newDays });
        props.backToOrganzations();
      })
      .catch((err) => {
        setLoading1(false);
        Toast.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="flex flex-row pb-3">
          <img
            onClick={() => props.backToOrganzations()}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
          />
          {/* <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 "> */}
          <p className=" text-xl xl:text-2xl  lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            {charity?.name}
          </p>
          {/* </div> */}
        </div>
        <div className="bg-white px-8">
          {isEditClicked ? (
            <div className="bg-white pt-8">
              <InputWithLabel
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputType="text"
                label="Organization Name"
                placeholder="Type Name"
              />
              <InputWithLabel
                inputType="file"
                setUrl={setLogo}
                label="Organization Logo"
              />
              <InputWithLabel
                inputType="file"
                setUrl={setImage}
                label="Featured Image"
              />
              <InputWithLabel
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputType="textarea"
                label="Organization Short Description"
                placeholder="Type Description"
              />
              <InputWithLabel
                value={stripeId}
                onChange={(e) => setStripeId(e.target.value)}
                inputType="text"
                label="Stripe ID"
                placeholder="Stripe Connect ID"
              />
              <InputWithLabel
                value={startedYear}
                onChange={(e) => setStartedYear(e.target.value)}
                inputType="text"
                label="Started year"
                placeholder="Started in year"
              />
              <div className="mt-5 font-Poppins">
                <CheckBox
                  setIsChecked={() => setIsVerified(!isVerified)}
                  isChecked={isVerified}
                  label="Is verified"
                />
              </div>
              {
                isVerified && (
                  <InputWithLabel
                  inputType="text"
                  value={certificate}
                  onChange={(e) => setCertificate(e.target.value)}
                  label="Certificate"
                />
                )
              }
            </div>
          ) : (
            <DetailsForm data={detailsData} />
          )}
          <div className="pb-4 mt-10">
            <div className="inline-block w-36 mr-6">
              <Button
                loading={loading}
                action={
                  isEditClicked ? () => saveHandler() : () => editHandler()
                }
                title={isEditClicked ? "Save changes" : "Edit"}
              />
            </div>
            <div className="inline-block w-24">
              <Button
                type="delete"
                loading={loading1}
                action={deleteHandler}
                title="Delete"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
