import React, { useState, useEffect, useReducer, useContext } from "react";
import { Context } from "../utils/store";
import user_with_billing from "../assets/icons/user_with_billing.svg";
import user_without_billing from "../assets/icons/users_without_billing.svg";
import fire from "../assets/icons/no_of_days_celebrate.svg";
import donate from "../assets/icons/donations_processed.svg";
import download from "../assets/icons/download.svg";
import CheckBox from "../components/CheckBox";
import axios from "../utils/axios";
import Table from "../components/Table";
import moment from "moment";
import { useCookies } from "react-cookie";
import { setConstantValue } from "typescript";
import Paginator from "../components/Paginator";
export default function Dashboard(props: any) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [transactions, setTransactions] = React.useState(null);
  const [funFacts, setFunFacts] = React.useState(null);
  const { state, dispatch } = useContext(Context);
  const [isTableShown, setIsTableShown] = useState(false);
  const UserIndex = 0;
  const EmailIndex = 1;
  const DateIndex = 4;
  const TransactionIndex = 2;
  const AmountIndex = 3;
  const ActionIndex = 5;
  const initialTableData = [
    {
      title: "User Name",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Email",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Stripe ID",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Amount",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Date",
      contentType: "text",
      data: ["02-10-2020", "02-10-2020", "02-10-2020", "02-10-2020"],
    },
    {
      title: "Action",
      contentType: "button",
      buttonName: "View",
      buttonType: "default",
      action: null,
    },
  ];

  const [tableData, setTableData] = React.useState(initialTableData);

  const [page, setPage] = useState(1);
  const limit = 25;
  const debounceTimeout = 1000;
  const [keyword, setKeyword] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTimeoutObj, setSearchTimeoutObj] = useState(null);
  const [loader, setLoader] = useState(false);
  const handlePaginationButtonClick = (direction, prevPage, nextPage) => {
    setPage(nextPage);
  };
  
  const getAllTransactions = async (payload = null) => {
    setLoader(true);
    await axios({
      method: "post",
      url: "/alltransactions",
      data: {
        page,
        limit,
        keyword,
        ...payload,
      },
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    }).then((response) => {
      const {
        transactions: { docs, totalRecords, totalPages },
      } = response.data;
      
      dispatch({
        type: "transactions",
        transactions: docs,
      });
      setTransactions(docs);
      setTotalRecords(totalRecords);
      setTotalPages(totalPages);
    });
    setLoader(false);
  };
  
  const handlePaginationDebounce = (page) => {
    getAllTransactions({ page });
  };

  React.useEffect(() => {
    getAllTransactions();
  }, []);

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    setKeyword(value);
    if (searchTimeoutObj) {
      clearTimeout(searchTimeoutObj);
    }
    const obj = setTimeout(() => {
      getAllTransactions({ keyword: value, page: 1 });
      setPage(1);
    }, debounceTimeout);
    setSearchTimeoutObj(obj);
  };

  React.useEffect(() => {
    setLoader(true);
    const getSingleTransaction = async () => {
      await axios({
        method: "get",
        url: `/transactions/${state?.params?.id}`,
        headers: {
          Authorization: `Bearer ${cookies.tokenId}`,
        },
      }).then((response) => {
        console.log(response);
      });
    };
    getSingleTransaction();
    setLoader(false);
  }, []);

  React.useEffect(() => {
    const getFunFacts = async () => {
      if (state.dashboardInfo == null) {
        await axios({
          method: "get",
          url: "/allfunfacts",
          headers: {
            Authorization: `Bearer ${cookies.tokenId}`,
          },
        }).then((response) => {
          dispatch({
            type: "dashboardInfo",
            dashboardInfo: response.data.response,
          });
          setFunFacts(response.data.response);
        });
      } else {
        setFunFacts(state.dashboardInfo);
      }
    };
    getFunFacts();
  }, [state]);

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  useEffect(() => {
    setTableDataHandler();
  }, [transactions]);

  // console.log(transactions)
  const setTableDataHandler = () => {
    let dates = [];
    let transactionId = [];
    let amount = [];
    let user = [];
    let email = [];
    if (transactions) {
      transactions.forEach(async (oneCharitie) => {
        transactionId.push(oneCharitie.stripeTransactionId);
        user.push(
          oneCharitie.donationData.userFirstName +
            " " +
            oneCharitie.donationData.userLastName
        );
        email.push(oneCharitie && oneCharitie.donationData && oneCharitie.donationData.userEmail ? oneCharitie.donationData.userEmail : "")
        // await axios({
        //   method: "get",
        //   url: `/transactions/${oneCharitie.stripeTransactionId}`,
        //   headers: {
        //     Authorization: `Bearer ${cookies.tokenId}`,
        //   },
        // }).then((response) => {
        //   console.log(response.data.transactionDetails[0].stripeTransactionId, response.data.transactionDetails[0].donationData.userFirstName);
        //   if(response.data.transactionDetails[0].stripeTransactionId == oneCharitie.stripeTransactionId)
        //   {user.push(response.data.transactionDetails[0].donationData.userFirstName + " " + response.data.transactionDetails[0].donationData.userLastName)}

        // });

        let unix_timestamp = oneCharitie.transactionDate;
        var date = new Date(unix_timestamp * 1000);
        dates.push(moment(oneCharitie.transactionDate).format("MM/DD/YYYY"));

        if (oneCharitie.donationData.coverTransactionFee == true) {
          amount.push(
            "USD " +
              (
                (oneCharitie.transactionAmount +
                  (oneCharitie.transactionAmount / 100) * 8) /
                1
              ).toFixed(2)
          );
        } else {
          amount.push("USD " + (oneCharitie.transactionAmount / 1).toFixed(2));
        }
      });
      let newTableData = [...tableData];
      newTableData[DateIndex].data = dates;
      newTableData[TransactionIndex].data = transactionId;
      newTableData[AmountIndex].data = amount;
      newTableData[UserIndex].data = user;
      newTableData[EmailIndex].data = email;
      newTableData[ActionIndex].action = viewHandler;
      setTableData(newTableData);
      setIsTableShown(true);
    }
  };

  const viewHandler = (index: number) => {
    let unix_timestamp = transactions[index].transactionDate;
    let total = [];
    if (transactions[index].donationData.coverTransactionFee == true) {
      total.push(
        transactions[index].transactionAmount +
          (transactions[index].transactionAmount / 100) * 8
      );
    } else {
      total.push(transactions[index].transactionAmount);
    }
    var date = new Date(unix_timestamp * 1000);
    dispatch({
      type: "params",
      params: {
        ...state.params,
        total: total,
        user:
          transactions[index].donationData.userFirstName +
          " " +
          transactions[index].donationData.userLastName,
        id: transactions[index].stripeTransactionId,
        date: moment(transactions[index].transactionDate).format("MM/DD/YYYY"),
      },
    });
    props.action();
  };
  
  return (
    <>
      <div className="grid 2xl:grid-cols-4 md:grid-cols-2 gap-x-4 gap-y-4">
        <div
          style={{ boxShadow: "0px 6px 20px #00000012" }}
          className="rounded-3xl shadow-lg"
        >
          <div className="flex flex-row">
            <div
              style={{ boxShadow: "0px 4px 12px #00000029" }}
              className="bg-camel rounded-3xl p-5 m-3 "
            >
              <img className="w-8" src={donate} />
            </div>
            <p className="font-PoppinsMedium mt-auto mb-auto pr-5 2xl:text-base text-sm">
              Donations processed (Last 30 Days)
            </p>
          </div>
          <p className="text-camel font-PoppinsMedium  text-center mb-4 text-4xl pt-1">
            $
            <span className="font-TrashHand  text-5xl">
              {kFormatter(funFacts?.totalDonated ? funFacts?.totalDonated : 0)}
            </span>
          </p>
        </div>
        <div
          style={{ boxShadow: "0px 6px 20px #00000012" }}
          className="rounded-3xl shadow-lg"
        >
          <div className="flex flex-row">
            <div
              style={{ boxShadow: "0px 4px 12px #00000029" }}
              className="bg-camel rounded-3xl p-5  m-3 "
            >
              <img src={user_with_billing} />
            </div>
            <p className="font-PoppinsMedium mt-auto mb-auto pr-5 2xl:text-base text-sm">
              Users with Billing Schedule
            </p>
          </div>
          <p className="text-camel font-PoppinsMedium  text-center mb-4 text-4xl pt-1">
            <span className="font-TrashHand  text-5xl">{kFormatter(funFacts?.usersWithScheduledDonation ? funFacts?.usersWithScheduledDonation : 0)}</span>
          </p>
        </div>
        <div
          style={{ boxShadow: "0px 6px 20px #00000012" }}
          className="rounded-3xl shadow-lg"
        >
          <div className="flex flex-row">
            <div
              style={{ boxShadow: "0px 4px 12px #00000029" }}
              className="bg-camel rounded-3xl p-5  m-3 "
            >
              <img src={user_without_billing} />
            </div>
            <p className="font-PoppinsMedium mt-auto mb-auto pr-5 2xl:text-base text-sm">
              User without Billing Schedule
            </p>
          </div>
          <p className="text-camel font-PoppinsMedium  text-center mb-4 text-4xl pt-1">
            <span className="font-TrashHand  text-5xl">{kFormatter(funFacts?.usersWithoutScheduledDonation ? funFacts?.usersWithoutScheduledDonation : 0)}</span>
          </p>
        </div>
        <div
          style={{ boxShadow: "0px 6px 20px #00000012" }}
          className="rounded-3xl shadow-lg"
        >
          <div className="flex flex-row">
            <div
              style={{ boxShadow: "0px 4px 12px #00000029" }}
              className="bg-camel rounded-3xl p-5 m-3 "
            >
              <img src={fire} />
            </div>
            <p className="font-PoppinsMedium mt-6 pr-5 2xl:text-base text-sm">
              No. Of Days celebrate
            </p>
          </div>
          <p className="text-camel font-PoppinsMedium  text-center mb-4 text-4xl pt-1">
            <span className="font-TrashHand  text-5xl">
              {funFacts?.daysCelebrated}D
            </span>
          </p>
        </div>
      </div>

      <div
        style={{ backgroundColor: "#f6f6f6" }}
        className={`pt-5 rounded-3xl mt-12 relative z-30 `}
      >
        <div className="grid lg:flex lg:flex-row md:grid-cols-1 mx-8 border-b-2 pb-5 mb-5">
          <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 ml-auto mr-auto pb-3 lg:pb-0 font-PoppinsSemiBold lg:mr-2  2xl:pb-0">
            New Transactions by Users
          </p>
          <input
            style={{ backgroundColor: "#fff" }}
            className={`mt-auto mb-auto rounded-lg 2xl:ml-auto  2xl:mr-0 flex-grow border-none lg:max-w-xs mb-auto mb-5 2xl:mb-0 focus:ring-gray-200  w-full focus:outline-none font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block border-none  pl-5 pr-5 sm:text-sm text-xs 2xl:text-base  rounded-xl  h-12 z-20 relative  `}
            placeholder="Search User ID, Name and Email"
            onChange={handleSearch}
          />
          {/* <div className="flex flex-row 2xl:mb-auto 2xl:mt-auto  mt-5 lg:ml-7 ml-auto 2xl:mr-10  mr-auto ">
            <img className=" mr-3  mb-auto  w-5" src={download} />

            <p className="text-brownGrey 2xl:text-base text-sm font-PoppinsMedium  ">
              Last 30 Days Report
            </p>
          </div> */}
        </div>
        {/* < hr/> */}
        <div
          style={{
            backgroundColor: "#f6f6f6",
            boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
            overflowX: "auto"
          }}
          className={`-mt-5  rounded-3xl`}
        >
          {isTableShown ? (
            <>
              {
                loader ? <div className="loader"></div> : <Table tableData={tableData} rows={transactions?.length} />
              } 
            </>
          ) : (
            <p className="p-4 text-sm">No donation has been processed yet</p>
          )}
        </div>
        <Paginator
          page={page}
          limit={limit}
          totalRecords={totalRecords}
          totalPages={totalPages}
          handleClick={handlePaginationButtonClick}
          debounceCallback={handlePaginationDebounce}
          timeout={debounceTimeout}
        />
      </div>
    </>
  );
}

const Transaction = (props: any) => {
  const [check, setChecked] = React.useState(true);
  return (
    <>
      <div className="table-row justify-between bg-white  font-PoppinsMedium     ">
        <div className="font-Poppins  ml-10">
          <CheckBox isChecked={check} setIsChanged={() => setChecked(!check)} />
        </div>
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center py-4">
          {props.name}
        </p>
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center">
          {props.id}
        </p>
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center">
          USD {props.amount}
        </p>
        <p className="table-cell text-black sm:text-sm text-xs 2xl:text-base text-center">
          {props.date}
        </p>
        <p className="text-center text-black">
          <button
            onClick={props.action}
            style={{ backgroundColor: "#f5ecd7" }}
            className="table-cell my-2 sm:text-sm text-xs 2xl:text-base py-0.5 px-6 rounded-md hover:opacity-80 relative z-20 focus:outline-none  "
          >
            View
          </button>
        </p>
      </div>
      <div className="mb-2"></div>
    </>
  );
};
