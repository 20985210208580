export default function PlusMinusCircle(props:any) {

	const handler = () => {
		if(props?.isActive){
			props.action(props.indexOfDay)
		}
	}

	// console.log(props.op)
	if(props.op=="+"){
		// console.log(props.op)
		return(
		    <button onClick={handler} className="inline-block bg-lightGrey2 w-8 h-8 rounded-full border-2 border-darkGreen focus:outline-none hover:opacity-80 ml-2 mr-4">
		    	<p className="text-darkGreen text-2xl text-center -ml-0.5 -mt-1.5">＋</p>
		 	</button>
	    )
	}

	else{
	    return(
		    <button onClick={handler} className={props.isActive ? "inline-block bg-lightGrey2 -ml-1 2xl:-ml-2 w-8 h-8 rounded-full border-2 border-red focus:outline-none hover:opacity-80" : "inline-block bg-lightGrey2 w-8 h-8 rounded-full border-2 border-red focus:outline-none opacity-0"}>
		    	<p className="text-red text-2xl text-center -mt-1.5 -ml-0.5">–</p>
		 	</button>
		)
	}
}