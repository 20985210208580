import React, { useEffect, useState,useContext } from "react";
import axios from "../utils/axios";
import { Context } from "../utils/store";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import Button from "../components/Button";
import CheckBox from "../components/CheckBox";
import { InputWithLabel } from "../components/Input";

export default function AddPersonalDate(props: any) {
    const { state, dispatch } = useContext(Context);

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
      });

    const [cookies] = useCookies();
    const [name, setName] = useState();
    const [icon, setIcon] = useState();
    const [isMultiple, setIsMultiple] = useState(true);
    const [allowManualEntry, setAllowManualEntry] = useState(true);
    const [manualEntryText, setManualEntryText] = useState("");
    const [placeholderText, setPlaceholderText] = useState("");  

    const [loading, setLoading] = useState(false);

    const addNew = () => {
        setLoading(true);  
        const data = {
          name,
          icon,
          isMultiple,
          status: true,
          allowManualEntry,
          manualEntryText,
          newEvent: "",
          placeholderText,
        };

        axios({
          method: "post",
          url: "/personaldays",
          headers: {
            Authorization: `Bearer ${cookies.tokenId}`,
          },
          data: data,
        })
          .then(async (response: any) => {
            Toast.fire({
              title: `Personal Day ${name} has been added`,
              icon: "success",
            }); 
            setLoading(false);
            let newPersonalDays = [...state.personalDays]
            newPersonalDays.push({...response.data.response})
            dispatch({ type: 'personalDays', personalDays: newPersonalDays })
            props.backToPersonalDays()
          })
          .catch((err) => {
              console.log(err)
            Toast.fire({
              title: "Error",
              icon: "error",
            });
            setLoading(false);
          });
      };

    return (
      <>
        <div
          style={{
            backgroundColor: "#f6f6f6",
            boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
          }}
          className={`pt-4  rounded-3xl relative z-20  `}
        >
          <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
            <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
              Add Personal Day
            </p>
          </div>
          <div className="bg-white px-8   ">
            <div className="bg-white grid gap-y-8 pt-8">
            <InputWithLabel
                onChange={(e) => setName(e.target.value)}
                inputType="text"
                label="Title"
                placeholder="Type your title"
              />
              <InputWithLabel inputType="file" setUrl={setIcon} label="Icon" />
            </div>
            <div className="font-Poppins mt-5">
              <CheckBox  setIsChecked={setIsMultiple} label="Enable Add/Delete option" isChecked={isMultiple} />
            </div>
            <div className="font-Poppins my-10">
              <CheckBox setIsChecked={setAllowManualEntry} label="Add Text Box for data input" isChecked={allowManualEntry} />
            </div>
            { allowManualEntry ? 
                  <InputWithLabel
                  value={placeholderText}
                  onChange={(e) => setPlaceholderText(e.target.value)}
                  inputType="text"
                  label="Placeholder text"
                  placeholder="Type placeholder name"
                /> : null
              }
            <div className="md:w-40 pb-4 mt-10">
              {" "}
              <Button loading={loading} action={addNew} title="Add Now" />
            </div>
          </div>
        </div>
      </>
    );
}