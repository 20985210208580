import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { ResponsiveEmbed } from "react-bootstrap";
import axios from "../utils/axios";
import moment from "moment";
import Swal from "sweetalert2";
import Table from "../components/Table";
import PersonalDayDetails from './PersonalDayDetails';
import { Context } from "../utils/store";

export default function PersonalImportDate(props: any) {
  const { state, dispatch } = useContext(Context);
  const [personalDays, setPersonalDays] = useState([]);
  const [cookies] = useCookies();
	const Toast = Swal.mixin({
		toast: true,
		position: "top",
		showConfirmButton: false,
		timer: 5000,
	  });
  const initialTableData = [
    { title: "Personal Day Name", contentType: "text", data: [] },
    {
      title: "Action",
      contentType: "button",
      buttonName: "View",
      buttonType: "default",
      action: null,
    },
    {
      title: "Status",
      contentType: "button",
      buttonType: "status",
      status: null,
      action: null,
    },
  ];

  const OrganizationIndex = 0;
	const ActionIndex = 1;
	const StatusIndex = 2;

  useEffect(() => {
    if(state?.personalDays){
      setPersonalDays(state.personalDays);
    } else{
    axios.get("/personaldays")
      .then((response: any) => {
        setPersonalDays(response.data.response);
        dispatch({ type: 'personalDays', personalDays: response.data.response })
      })
      .catch((err) => console.log(err));
    }
  }, [state]);

  useEffect(() => {
    setTableDataHandler();
  }, [personalDays]);

  const [showPersonalDayDetails, setShowPersonalDayDetails] = useState(false);
  const [personalDayID, setPersonalDayID] = useState(null);
  const [isTableShown, setIsTableShown] = useState(false);
  const [tableData, setTableData] = useState(initialTableData)

  const setTableDataHandler = () => {
    let personalDaysTitles = [];
    let statuses = [];
    if (personalDays) {
      personalDays?.forEach((personalDay) => {
        personalDaysTitles.push(personalDay.name);
        // if (personalDay?.addedOn) {
        //   dates.push(moment(personalDay.addedOn).format("DD-MM-YYYY"));
        // } else {
        //   dates.push("");
        // }

        if (personalDay.status) {
          statuses.push(true);
        } else if (!personalDay.status) {
          statuses.push(false);
        }
      });
    }

    let newTableData = [...tableData];
    newTableData[OrganizationIndex].data = personalDaysTitles;
    newTableData[ActionIndex].action = viewHandler;
    newTableData[StatusIndex].status = statuses;
    newTableData[StatusIndex].action = statusHandler;
    setTableData(newTableData);
    setIsTableShown(true);
  };

	const statusHandler = (index) => {
		let newTableData = [...tableData]
		const data = {
			status: !newTableData[StatusIndex].status[index],
			id: personalDays[index]._id
		}
		axios({
			method: "post",
			url: "/changepersonalday",
			headers: {
			  Authorization: `Bearer ${cookies.tokenId}`,
			},
			data: data,
		  })
		  .then((response)=>{
        newTableData[StatusIndex].status[index] = !newTableData[StatusIndex].status[index]
        setTableData(newTableData)
        let newPersonalDays = [...personalDays];
        newPersonalDays[index].status = newTableData[StatusIndex].status[index]
        dispatch({ type: 'personalDays', personalDays: newPersonalDays })
        Toast.fire({
          title: `${response.data.response}`,
          icon: "success",
        });
		  })
		  .catch((err)=>{
			  console.log(err)
      })
	}

  const viewHandler = (index:number) => {
		setShowPersonalDayDetails(true)
		setPersonalDayID(personalDays[index]._id)
  }
  
	if(showPersonalDayDetails && personalDayID){
		return (<PersonalDayDetails id={personalDayID} backToPersonalDate={()=>setShowPersonalDayDetails(false)} />)
	}
    return (
      <>
        <div
          style={{
            backgroundColor: "#f6f6f6",
            boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
          }}
          className={`pt-4  rounded-3xl   `}
        >
          <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
            <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
              Personal Days
            </p>
            <button
              onClick={() => {
                props.action();
              }}
              style={{
                color: "#c7ad1f",
                boxShadow: " 0 1.5px 5px 0 rgba(56, 66, 115, 0.11)",
              }}
              className="focus:outline-none bg-white rounded-3xl text-white text-sm md:text-bas ml-auto font-Poppins lg:w-48 w-full ml-auto h-12 lg:mt-auto lg:mb-auto mb-2 py-3  z-20 relative hover:opacity-80 "
            >
              Add New
            </button>
          </div>
          {/* Cause */}
          {isTableShown ? (
            <Table tableData={tableData} rows={personalDays?.length} />
          ) : null}
        </div>
      </>
    );
}
