import React, { useRef, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import Button from "../components/Button";
import { Context } from "../utils/store";
import { InputWithLabel } from "../components/Input";
import Swal from "sweetalert2";
import CheckBox from "../components/CheckBox";

export default function AddOrganization(props) {
  const { state, dispatch } = useContext(Context);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies] = useCookies();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [stripeId, setStripeId] = useState();
  const [selectedLogo, setSelectedLogo] = useState("No file Chosen");
  const [selectedImage, setSelectedImage] = useState("No file Chosen");
  const [logo, setLogo] = useState();
  const [image, setImage] = useState();
  const [startedYear, setStartedYear] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [certificate, setCertificate] = useState();

  const addNow = () => {
    setLoading(true);
    const data = {
      name,
      logo,
      description,
      image,
      addedOn: new Date(),
      isActive: true,
      stripeId: stripeId,
      startedYear,
      isVerified,
      certificate,
    };
    axios({
      method: "post",
      url: "/charities",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: data,
    })
      .then(async (response: any) => {
        Toast.fire({
          title: `Organization ${name} has been added`,
          icon: "success",
        });
        setName("");
        setDescription("");
        setStripeId("");
        setStartedYear("");
        setLoading(false);

        let newCharities = [...state.charities];
        newCharities.push({ ...response.data.response });
        dispatch({ type: "charities", charities: newCharities });
        props.backToOrganzations();
      })
      .catch(() => {
        Toast.fire({
          title: "Error",
          icon: "error",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f6f6f6",
          boxShadow: "0 2.5px 5px 0 rgba(56, 66, 115, 0.15)",
        }}
        className="pt-4 rounded-3xl relative z-20"
      >
        <div className="grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ">
          <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">
            Add Organization
          </p>
        </div>
        <div className="bg-white px-8">
          <div className="bg-white pt-8">
            <InputWithLabel
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputType="text"
              label="Organization Name"
              placeholder="Type Name"
            />
            <InputWithLabel
              inputType="file"
              setUrl={setLogo}
              label="Organization Logo"
            />
            <InputWithLabel
              inputType="file"
              setUrl={setImage}
              label="Featured Image"
            />
            <InputWithLabel
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputType="textarea"
              label="Organization Short Description"
              placeholder="Type Description"
            />
            <InputWithLabel
              value={stripeId}
              onChange={(e) => setStripeId(e.target.value)}
              inputType="text"
              label="Stripe ID"
              placeholder="Stripe Connect ID"
            />
            <InputWithLabel
              value={startedYear}
              onChange={(e) => setStartedYear(e.target.value)}
              inputType="text"
              label="Started year"
              placeholder="Started in year"
            />
            <div className="mt-5 mb-10 font-Poppins relative z-20">
              <CheckBox
                label="Is Verified?"
                isChecked={isVerified}
                setIsChecked={() => setIsVerified(!isVerified)}
              />
            </div>
            {isVerified && (
               <InputWithLabel
               inputType="text"
               value={certificate}
               onChange={(e) => setCertificate(e.target.value)}
               label="Certificate"
             />
            )}
          </div>
          <div className="md:w-40 pb-4 mt-10">
            <Button loading={loading} action={addNow} title="Add Now" />
          </div>
        </div>
      </div>
    </>
  );
}
