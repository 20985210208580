import React, { useState, useEffect, useReducer, useContext } from "react";
import download from "../assets/icons/download.svg";
import sms from "../assets/icons/1.png";
import { Context } from "../utils/store";
import gmail from "../assets/icons/2.png";
import { useCookies } from "react-cookie";
import axios from "../utils/axios";
import moment from "moment";
import ArrowLeft from "../assets/icons/ArrowLeft.png";

export default function TransactionDetails(props: any) {
  const { state, dispatch } = useContext(Context);
  const [transaction, setTransaction] = React.useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [charity, setCharity] = useState([]);
  const [charityName, setCharityName] = useState();
  const [transactionFees, setTransactionFees] = useState(0);

  React.useEffect(() => {
    const getSingleTransaction = async () => {
      const response = await axios({
        method: "get",
        url: `/transactions/${state?.params?.id}`,
        headers: {
          Authorization: `Bearer ${cookies.tokenId}`,
        },
      }).then((response) => {
        // console.log(response);
        setTransaction(response.data.transactionDetails[0]);
      });
    };
    getSingleTransaction();
    getSettings();
  }, [0]);

  useEffect(() => {
    axios.get("/charities").then((response) => {
      setCharity(response.data.charities);
    });
  }, [0]);

  useEffect(() => {
    let name: any = [];
    if (charity) {
      charity.forEach((oneCharitie: any) => {
        //console.log(transaction)
        transaction?.donationData.charityData.map((oneCharity, index) => {
          if (oneCharitie._id == oneCharity.charityId) {
            if (oneCharity.contributions.length != 0) {
              name.push(oneCharitie.name);
            }
          }
          setCharityName(name);
        });
      });
    }
  }, [charity, transaction]);

  const getSettings = () => {
    axios
      .get("/config-settings")
      .then((response) => {
        setTransactionFees(response.data.response.TRANSACTION_FEE);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let transDetails = null;

  transDetails = charity.map((charity, index) => {
    return (
      <div className=" grid gap-y-1 flex">
        <p
          key={index}
          className="flex-1  text-sm 2xl:text-base -mb-3  font-PoppinsMedium text-black table-cell "
        >
          {transaction?.donationData?.charityData[index]?.contributions.length >
          0 ? (
            <>
              <span key={index}>{charity.name}</span>
            </>
          ) : null}
        </p>
        <p className="flex-1 font-PoppinsMedium self-end text-left mb-auto text-sm 2xl:text-base text-black mr-2 w-full flex">
          <span className="text-brownGrey -mt-6 2xl:-mt-7 ml-40 w-full gap-y-2  grid ">
            {transaction?.donationData?.charityData.map((cause, index) => {
              if (cause.charityId == charity._id) {
                return cause.contributions.map((oneCause, index) => {
                  return (
                    <>
                      <div key={index} className="flex">
                        <span className="flex-1 w-40">{oneCause.name}</span>
                        <span className="flex-none float-right text-sm 2xl:text-base text-black  font-Poppins ">
                          {/* ${oneCause.amount} */}
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(oneCause.amount)}
                        </span>
                      </div>
                      <br />
                    </>
                  );
                });
              }
            })}
          </span>
        </p>
      </div>
    );
  });
  let mobileDetails = null;

  mobileDetails = charity.map((charity, index) => {
    return (
      <>
        <p
          key={index}
          className="flex  mt-5 text-sm 2xl:text-base mb-4 font-PoppinsMedium text-black  "
        >
          {transaction?.donationData?.charityData[index]?.contributions.length >
          0 ? (
            <>
              <span key={index}>Charity: {charity.name}</span>
            </>
          ) : null}
        </p>
        <p className=" font-PoppinsMedium  mb-auto text-sm 2xl:text-base text-black mr-2 w-full ">
          <span className="text-brownGrey  gap-y-2  ">
            {transaction?.donationData?.charityData.map((cause, index) => {
              if (cause.charityId == charity._id) {
                return cause.contributions.map((oneCause, index) => {
                  return (
                    <>
                      <div key={index} className="">
                        <span>{oneCause.name}</span>
                        <span className="float-right  ml-auto text-sm 2xl:text-base text-black  font-Poppins ">
                          ${oneCause.amount}
                        </span>
                      </div>
                      <br />
                    </>
                  );
                });
              }
            })}
          </span>
        </p>
      </>
    );
  });

  return (
    <>
      <div
        style={{ backgroundColor: "#f6f6f6" }}
        className={`py-6 pb-8 rounded-3xl sm:px-8 w-full px-2 z-100`}
      >
        {" "}
        <div className="flex flex-row pb-3">
          <img
            onClick={props.backToDashboard}
            className="my-auto ml-4 lg:mr-5 mr-0"
            src={ArrowLeft}
            alt="backarrao"
            style={{ cursor: "pointer" }}
          />
          <p className=" text-xl xl:text-2xl mt-auto mb-auto font-PoppinsSemiBold mr-auto lg:mr-2 pb-3 lg:pb-0">
            Transaction ID:{" "}
            <span className="text-camel font-PoppinsSemiBold 2xl:text-xl md:text-lg text-base my-2">
              {" "}
              #{transaction?.stripeTransactionId}{" "}
            </span>
          </p>
        </div>
        <div className="grid grid-cols-2 gap-y-5 gap-x-1">
          <div>
            <p className="text-black mt-auto 2xl:text-base text-sm font-PoppinsMedium">
              Date Charge
              <span className="text-brownGrey pl-2">{state?.params.date}</span>
            </p>
            <div className="flex flex-row justify-between pr-3 mb-5 mt-1 md:pr-0">
              <p className="text-black mt-auto 2xl:text-base text-sm font-PoppinsMedium">
                Frequency
                <span className="text-brownGrey pl-2">
                  {transaction?.donationData.frequency == "oneTime"
                    ? "One Time"
                    : "Reccuring"}
                </span>
              </p>
            </div>
            <div className="flex flex-row justify-between pr-3 md:pr-0 mb-1">
              <p className="text-darkGreen font-PoppinsSemiBold 2xl:text-xl md:text-lg text-base ">
                Payment Successful
              </p>
            </div>
            <div className=" grid md:grid-cols-2 grid-cols-1 md:gap-x-5 justify-between mt-7">
              <div className="sm:pr-2 pr-0">
                <p className="2xl:text-xl md:text-lg text-base text-black font-PoppinsSemiBold md:mb-5 mt-4 md:mt-0 ">
                  {transaction?.donationData.type &&
                  transaction?.donationData.type === "personal"
                    ? "Personal Days"
                    : transaction?.donationData.type === "islamic"
                    ? "Islamic Days"
                    : "Sunnah Days"}
                </p>
                <div
                  style={{
                    boxShadow: " 0px 3px 9px #00000012",
                  }}
                  className="bg-white rounded-xl w-full  pt-3 pb-4  px-3 my-2"
                >
                  <p className="text-black 2xl:text-base text-sm">
                    {transaction?.donationData.targetObj?.manualEntryText
                      ? transaction?.donationData.name +
                        " " +
                        transaction?.donationData.targetObj?.manualEntryText
                      : transaction?.donationData.name}
                  </p>
                  <p className="text-brownGrey 2xl:text-base text-sm">
                    Date
                    <span className="pl-2">
                      {moment(transaction?.donationData.donationDate).format(
                        "MM/DD/YYYY"
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="md:hidden 2xl:w-28rem mb-5 w-full justify-self-center xl:justify-items-auto bg-white rounded-xl shadow-round px-4 md:px-10 py-5 col-span-8 xl:col-span-4">
              <span className="flex-auto font-PoppinsMedium self-end text-left mb-auto text-sm 2xl:text-base text-black">
                {mobileDetails}
              </span>

              <p className="flex mt-3 text-sm 2xl:text-base my-2 font-PoppinsMedium text-black ">
                Total Donation
                <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(transaction?.donationData?.totalDonationAmt)}
                </span>
              </p>
              {transaction?.donationData?.coverTransactionFee &&
                <p className=" text-brownGrey 2xl:text-base text-sm flex w-full text-left ">
                  Transaction Fees
                  <span className=" 2xl:text-base text-sm text-black ml-auto  text-right ">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(transaction?.donationData?.totalDonationAmt * (transaction?.donationData?.coverTransactionFee ? transactionFees : 0))}
                  </span>
                </p>
              }
              <div className="border-b-2 mt-4 opacity-75"></div>
              <p className="flex mt-3  text-sm 2xl:text-base mt-6 text-left font-PoppinsMedium text-black">
                Total Charge
                <span className="flex-auto font-Poppins self-end text-right mb-auto text-sm 2xl:text-base text-black">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format((
                          transaction?.donationData?.totalDonationAmt +
                          transaction?.donationData?.totalDonationAmt * (transaction?.donationData?.coverTransactionFee ? transactionFees : 0)
                        ).toFixed(2)
                  )}
                </span>
              </p>
            </div>
            <div className="hidden md:flex bg-white rounded-3xl mt-6 pt-8 pb-6">
              <div className="table w-full ">
                <div className="flex justify-between text-black ">
                  <p className="font-PoppinsMedium text-black 2xl:text-base text-sm px-6 text-left pb-2">
                    Charity
                  </p>
                  <p className="font-PoppinsMedium text-black 2xl:text-base text-sm  px-6 text-left">
                    Cause
                  </p>
                  <p className="font-PoppinsMedium text-black 2xl:text-base text-sm  px-6 text-right">
                    Amount
                  </p>
                </div>
                <div className="border-b-2 mb-4 opacity-75"></div>

                <p className=" text-black 2xl:text-base text-sm   px-6 pt-4 ">
                  {transDetails}
                </p>

                <div className="flex flex-row justify-between ">
                  <p className=" text-black 2xl:text-base text-sm table-cell text-left px-6 pt-1">
                    Total Donation
                  </p>
                  <p></p>
                  <p className=" 2xl:text-base text-sm text-black table-cell text-right px-6">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(transaction?.donationData?.totalDonationAmt)}
                  </p>
                </div>
                {transaction?.donationData?.coverTransactionFee && 
                  <div className="flex flex-row justify-between shadow-sm">
                    <p className=" text-brownGrey 2xl:text-base text-sm table-cell text-left px-6 pb-5 pt-2">
                      Transaction Fees
                    </p>
                    <p></p>
                    <p className=" 2xl:text-base text-sm text-black table-cell text-right px-6">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(
                        transaction?.donationData?.totalDonationAmt * (transaction?.donationData?.coverTransactionFee ? transactionFees : 0)
                      )}
                    </p>
                  </div>
                }
                <div className="flex flex-row justify-between">
                  <p className=" text-black 2xl:text-base text-sm table-cell text-left px-6 pt-3">
                    Total Charge
                  </p>
                  <p></p>
                  <p className=" 2xl:text-base text-sm text-black table-cell text-right mt-auto px-6">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format((
                            transaction?.donationData?.totalDonationAmt +
                            transaction?.donationData?.totalDonationAmt * (transaction?.donationData?.coverTransactionFee ? transactionFees : 0)
                          ).toFixed(2)
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-white rounded-3xl py-6 px-7"
            style={{ maxHeight: "200px" }}
          >
            <div className="flex justify-between text-black ">
              <p
                style={{ width: "50%", wordWrap: "break-word" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                First Name :
              </p>
              <p
                style={{ width: "50%" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                {transaction?.donationData?.userFirstName}
              </p>
            </div>
            <div className="flex justify-between text-black ">
              <p
                style={{ width: "50%" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                Last Name :
              </p>
              <p
                style={{ width: "50%" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                {" "}
                {transaction?.donationData?.userLastName}
              </p>
            </div>
            <div className="flex justify-between text-black ">
              <p
                style={{ width: "50%" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                Email :
              </p>
              <p
                style={{ width: "50%", wordWrap: "break-word" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                {transaction?.donationData?.userEmail}
              </p>
            </div>

            <div className="flex justify-between text-black ">
              <p
                style={{ width: "50%" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                Stripe Card Token :
              </p>
              <p
                style={{ width: "50%", wordWrap: "break-word" }}
                className="font-PoppinsMedium text-sm pb-2"
              >
                {transaction?.donationData?.stripeCardToken}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const Contribution = (props: any) => {
  return (
    <>
      <p
        style={{ color: "#384273" }}
        className="font-PoppinsMedium mt-auto mb-auto 2xl:text-base text-sm   pr-4  md:pr-2   pt-2 table-cell"
      >
        {props.name}
      </p>
      <br />
      {/* <p
        style={{ color: "#9499b5" }}
        className="font-Poppins mt-auto mb-auto 2xl:text-base text-sm   pr-4  md:pr-2   table-cell"
      >
        Muharram 1st ${props.amount}, Muharram 1st $10,
      </p> */}
      <br />
    </>
  );
};
