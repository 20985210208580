import React, {useState, useContext, useEffect} from 'react'
import Button from '../components/Button'
import CheckBox from '../components/CheckBox'
import {InputWithLabel} from '../components/Input'
import { useCookies } from "react-cookie";
import { Context } from "../utils/store";
import axios from '../utils/axios'
import Swal from "sweetalert2";

export default function AddCause(props:any) {
    const { state, dispatch } = useContext(Context);
    const [cookies] = useCookies();
    
    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
    });

    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [causeBelongingOptions, setCauseBelongingOptions] = useState()
    const [causeBelongingSelectedOption, setCauseBelongingSelectedOption] = useState()
    const [isZakat, setIsZakat] = useState(true)
    const [logo, setLogo] = useState()
    const [icon, setIcon] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        let newCauseBelongingOptions = state.charities.map((oneCharity)=>oneCharity.name)
        setCauseBelongingOptions(newCauseBelongingOptions)
    },[state.charities])

    const addNow = () => {
        setLoading(true)
        const params = {
            name,
            parentCharity: findIdOfCharity(causeBelongingSelectedOption),
            image: logo,
            isZakat,
            description,
            status: 1,
            isActive: true,
            icon,
        }
          axios({
            method: "post",
            url: "/causes",
            headers: {
              Authorization: `Bearer ${cookies.tokenId}`,
            },
            data: params,
          })
            .then(async (response: any) => {
                Toast.fire({
                    title: `Cause ${name} has been added`,
                    icon: "success",
                })
                let newCauses = [...state.causes]
                newCauses.push({...response.data.response})
                dispatch({ type: 'causes', causes: newCauses })
                props.backToCauses()
                setLoading(false)
            })
            .catch((err) =>{
              Toast.fire({
                title: "Error",
                icon: "error",
              })
              setLoading(false)
            });
    }

    const findIdOfCharity = (name:string) => {
        return state.charities[state.charities.findIndex((oneCharity)=>name==oneCharity.name)]._id;
    }

    return(
        <>
            <div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className="pt-4 rounded-3xl z-20 relative">
                <div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
                    <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Add Cause for donation</p>    
                </div>
                <div className='bg-white px-8'>
                    <div className='bg-white pt-8'>
                        <InputWithLabel value={name} onChange={(e)=>setName(e.target.value)} inputType="text" label="Cause Title" placeholder="Type cause"/>
                        <InputWithLabel
                          inputType="file"
                          setUrl={setLogo}
                          label="Cause Logo"
                        />
                        <InputWithLabel
                          inputType="file"
                          setUrl={setIcon}
                          label="Cause Icon"
                        />

                        <InputWithLabel onChange={(obj)=>setCauseBelongingSelectedOption(obj.value)} inputType="dropdown" label="Cause Belong to" placeholder="Select organization" options={causeBelongingOptions}/>   
                        <InputWithLabel value={description} onChange={(e)=>setDescription(e.target.value)} inputType="textarea" label="Cause Short Description" placeholder="Type Description"/>
                    </div>
                    <div className='mt-5 font-Poppins'> <CheckBox setIsChecked={()=>setIsZakat(!isZakat)} isChecked={isZakat} label='Cause Zakat eligible'/></div>
                    <div className='md:w-40 pb-4 mt-10'> <Button loading={loading} action={addNow} title='Add Now'/></div>
                </div>
            </div>
        </>
    )
}

