import Button from './Button';

export default function DetailsForm(props:any) {

    let data = props.data;

    let row = null;
    row = data.map((oneRow:object)=>{
        if(!("valueType" in oneRow)){
            return (<div className="flex flex-row ">
                <p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{oneRow.name}</p>
                <p className="inline-block pl-2 py-3 lg:ml-24 2xl:mr-0 focus:ring-gray-200 font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base w-96 z-20 relative">{oneRow.value}</p> 
                {oneRow.box ? (
                    <div className='-ml-72 my-auto mr-auto' style={oneRow.parametrs}>        
                    </div>
                ):null}
            </div>
            )
        } else if(("valueType" in oneRow && oneRow.valueType=="image")){
            return (<div className="flex flex-row ">
                <p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{oneRow.name}</p>
                <img src={oneRow.value} className="inline-block pl-2 py-3 md lg:ml-24 2xl:mr-0 focus:ring-gray-200  font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base w-44 z-20 relative"/>
            </div>
            )
        } else if(("valueType" in oneRow && oneRow.valueType=="button")){
            return (
                <div className="flex flex-row ">
                    <p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{oneRow.name}</p>
                    <div className="inline-block pl-2 py-3 md lg:ml-24 2xl:mr-0 focus:ring-gray-200  font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base w-44 z-20 relative">
                        <Button title="SHOW IN EDITOR" action={oneRow.action}/>
                    </div>
                </div>
            )
        } else if("valueType" in oneRow && oneRow.valueType=="arrayOfStrings"){
            let listOfStrings = oneRow.value.map((oneString:string)=>{
                return (
                    <li className="mt-2">{oneString}</li>
                )
            })
            return (<div className="flex flex-row ">
                <p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{oneRow.name}</p>
                <ul className="inline-block pl-2 py-3 lg:ml-24 2xl:mr-0 focus:ring-gray-200 font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base w-96 z-20 relative">
                    {listOfStrings}
                </ul>
            </div>
            )
        }
    })

    return(
        <div className="bg-white pt-8">
            {row}
        </div> 
    )

}