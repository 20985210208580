import React, { useState, useContext, useEffect } from "react";
import { Context } from "../utils/store";
import Button from "../components/Button";
import Input from "../components/Input";
import axios from "../utils/axios";
import Swal from "sweetalert2";
import CheckBox from "../components/CheckBox";
import { useCookies } from "react-cookie";
import logo from "../assets/logo.svg";
import { textSpanIntersectsWithPosition } from "typescript";

export default function Login() {
  const { state, dispatch } = useContext(Context);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  const [cookies, setCookie] = useCookies();

  const cookieHandler = (name, value) => {
    setCookie(name, value);
  };
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [isCodeInputShown, setIsCodeInputShown] = useState(false);
  const [mfaCode, setMfaCode] = useState(null);
  const [sessionValue, setSessionValue] = useState(null);
  const [userName, setUserName] = useState(null);
  
  
  
  const logIn = async () => {
    setLoading(true);
    const userData = {
      email,
      password,
    };
    await axios({
      method: "post",
      url: "/adminlogin",
      data: userData,
    })
      .then(async (response: any) => {
        setLoading(false);
        const responseCode = response.data.response.responseCode;
        const responseSession = response.data.response.session;
        const responseUserName = response.data.response.userName;

        if(responseCode === "SMSSENT"){
          setIsCodeInputShown(true);
          setSessionValue(responseSession)
          setUserName(responseUserName)
        }

        // Without sms verification:
        const responseMessage = response?.data?.message
        if(responseCode !== "SMSSENT" && responseMessage === "Success"){
          cookieHandler("id", response.data.response.id);
          cookieHandler("tokenId", response.data.response.idToken);
          cookieHandler("refreshToken", response.data.response.refreshToken);
          dispatch({ type: "isLoggedIn", isLoggedIn: true });
        }
        
      })
      .catch((err: any) => {
        console.log(err.response);
        setLoading(false);
        Toast.fire({
          title: err.response.data.response.message
            ? err.response.data.response.message
            : "The email or password is incorrect",
          icon: "error",
        });
      });
  };

  const verifyLogIn = async () => {
    setLoading(true);
    const data = {
      email,
      username: userName,
      sessionValue,
      mfaCode: +mfaCode
    };
    await axios({
      method: "post",
      url: "/verifyadminlogin",
      data
    }).then(async (response: any) => {
        setLoading(false);
        if (response.data.message == "Success") {
          cookieHandler("id", response.data.response.id);
          cookieHandler("tokenId", response.data.response.idToken);
          cookieHandler("refreshToken", response.data.response.refreshToken);
          dispatch({ type: "isLoggedIn", isLoggedIn: true });
        }
        if(response.data.message=="Error"){
          Toast.fire({
            title: response.data.response.message
              ? response.data.response.message
              : "Invalid code or auth state for the user.",
            icon: "error",
          });
        }
        setIsCodeInputShown(false)
      }).catch((err: any) => {
        Toast.fire({
          title: "Invalid verification code",
          icon: "error",
        })
        setIsCodeInputShown(false)
        setLoading(false);
      });
  }

  const rememberMeHandler = () => {
    setRememberMe(!rememberMe);
    cookieHandler("autoLogin", !rememberMe);
  };

  return (
    <div className="h-full relative">
      <div className="w-96 absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <div className="text-center">
          <a className="mx-auto inline-block" href="/">
            <img className="md:h-12 h-10 mb-4 -mt-8" src={logo} alt="Logo" />
          </a>
        </div>
        <Input
          onChange={(event: any) => setEmail(event.target.value)}
          type="text"
          title="Admin Email"
          placeholder="Enter your email"
        />
        <Input
          onChange={(event: any) => setPassword(event.target.value)}
          type="password"
          title="Admin Password"
          placeholder="Enter your password"
        />
        { isCodeInputShown ? 
          <Input
          onChange={(event: any) => setMfaCode(event.target.value)}
          type="text"
          title="SMS verification code"
          placeholder="Enter your code"
        />
        : null }
        <CheckBox
          setIsChecked={rememberMeHandler}
          isChecked={rememberMe}
          label="Remember me"
        />
        <br />
        <Button loading={loading} action={isCodeInputShown ? verifyLogIn : logIn} title="Log In" />
      </div>
    </div>
  );
}
