import React, { useRef, useState } from "react";
import Button from './Button';
import axios from "../utils/axios";
import { FiPaperclip } from 'react-icons/fi'
import { FiCheck } from "react-icons/fi";
import Dropdown from 'react-dropdown';
import '../styles/dropdown.css';
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Input(props: any) {
	return (
		<div>
			<div className='mt-1 rounded-md'>
				<label className='2xl:text-base text-sm'>{props.title}</label>
				<input
					style={{ backgroundColor: '#F1F1F1' }}
					type={props.type}
					onChange={props.onChange}
					className="focus:ring-gray-200 mt-2 mb-8 shadow-sm focus:outline-none focus:ring-2 placeholder-grey2 focus:border-gray-200 focus:outline-none block w-full pl-7 pr-12 sm:text-sm text-xs border-gray-300 rounded-md h-12"
					placeholder={props.placeholder}
				/>
			</div>
			<p className="text-errorColor text-sm -mt-5 mb-4">{props.error ? props.error : null}</p>
		</div>
	);
}

export function InputWithLabel(props: any) {
	const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
    });
	const [selectedImageName, setSelectedImageName] = useState('No file Chosen')
	const [imageFile, setImageFile] = useState(null)
	const [fileUploadingLoading, setFileUploadingLoading] = useState(false)
	const [imgHasUploaded, setImgHasUploaded] = useState(false)

	// Create a reference to the hidden file input element
  	const hiddenImageFileInput = useRef(null);


	const inputType  = props.inputType;
	let input = null;

	switch(inputType){
		case "text":
			input = 
				<div className="flex flex-row ">
					<p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{props.label}</p>
					<input
						value={props.value}
						type="text"
						onChange={props.onChange}
			            style={{ backgroundColor: '#fff', borderWidth: 1, borderColor: '#707070' }}
			            className="inline-block pl-2 py-3 rounded-md lg:ml-3 2xl:mr-0 focus:ring-gray-200  font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base w-64 relative"
			            placeholder={props.placeholder}
			        />
		        </div>
		break;
		case "phone":
			input = 
				<div className="flex flex-row">
					<p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{props.label}</p>
					<PhoneInput
                      country={"us"}
                      value={props.value}
                      placeholder={props.placeholder}
                      onChange={props.onChange}
					  style={{ backgroundColor: '#fff', borderWidth: 1, borderColor: '#707070', width : "26%" }}
                      className="z-100 inline-block pl-2 py-3 rounded-md lg:ml-3 2xl:mr-0 focus:ring-gray-200  font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base relative"
                      inputStyle={{ border: "none", backgroundColor: "transparent" }}
                      buttonStyle={{ border: "none", backgroundColor: "transparent" }}
                    />
		        </div>
		break;
		case "button":
			input = 
				<div className="flex flex-row ">
					<p className='inline-block w-44 font-Poppins 2xl:text-base text-sm self-center'>{props.label}</p>
					<div className="inline-block pl-2 py-3 rounded-md lg:ml-3 2xl:mr-0 focus:ring-gray-200  font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base w-44 relative">
						<Button title={props.btnTitle} action={props.action}/>
					</div>
		        </div>
		break;
		case "textarea":
		  	input = 
			<div className="flex flex-row">
				<p className=' inline-block w-44 font-Poppins 2xl:text-base text-sm'>{props.label}</p>
		  		<textarea
				rows={5}
          		cols={60}
          		value={props.value}
				onChange={props.onChange}
		        style={{ backgroundColor: '#fff', borderWidth: 1, borderColor: '#707070' }}
		        className="col-span-8 pl-2 py-3 ml-3 rounded-md focus:ring-gray-200  font-Poppins focus:ring-2 placeholder-grey2 focus:outline-none sm:text-sm text-xs 2xl:text-base relative"
		        placeholder={props.placeholder}
				/>
			</div>
		break;
		case "file":

			const handleImageClick = () => {
			hiddenImageFileInput.current.click();
			};
			const handleImageChange = (event: any) => {
			setImgHasUploaded(false)
			const imageFileObj = event.target.files[0];
			setSelectedImageName(imageFileObj.name);
			setImageFile(imageFileObj)
			};

			const uploadFileHandler = async (file: any) => {
				if(!file){
					return false;
				}
				setFileUploadingLoading(true)
				let formData = new FormData();
				formData.append("file", file);
				return await axios({
					method: "post",
					url: "/uploadimage",
					headers: {
						'Content-Type': `multipart/form-data`,
					},
					data: formData,
				}).then(async (response: any) => {
					setFileUploadingLoading(false)
					props.setUrl(response.data.file)
					setImgHasUploaded(true)
					setSelectedImageName(null)
				})
				.catch((err) => {
					setFileUploadingLoading(false)
					Toast.fire({
					title: "Error",
					icon: "error",
					});
				});
			}
		input = 
            <div className="flex flex-row ">
              <p className="inline-block w-44 font-Poppins 2xl:text-base text-sm my-auto">
                {props.label}
              </p>
              <div className="inline-block mt-1">
                <input
                  type="file"
                  ref={hiddenImageFileInput}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <FiPaperclip className="inline-block ml-3" />
                <button
                  style={{
                    borderWidth: 1,
                    borderColor: "#4e77b6",
                    color: "#4e77b6",
                  }}
                  className="inline-block ml-4 px-5 focus:outline-none py-1 rounded-lg hover:opacity-80"
                  onClick={handleImageClick}
                >
                  Choose File
                </button>
                <p className="inline-block font-Poppins text-brownGrey ml-4">
                 {selectedImageName}
                </p>
                <div className="w-40 inline-block ml-10">
                {imgHasUploaded ? 
                	<p className="">Uploaded</p>
                 : <Button loading={fileUploadingLoading} action={()=>uploadFileHandler(imageFile)} title="Upload"/>
                }
                </div>
              </div>
            </div>
		break;
		case "dropdown":
			input = 
				<div className="flex flex-row ">
					<p className='inline-block w-44 font-Poppins 2xl:text-base text-sm'>{props.label}</p>
					<div className="inline-block ml-3">
						<Dropdown options={props.options} value={props.selectedOption} onChange={props.onChange} placeholder={props.placeholder} className='font-Poppins text-sm 2xl:text-base' />
					</div>
				</div>	
	}

	return (
		<div className="mb-6">
			{input}
		</div>
	);
} 