import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../assets/logo.svg';
import { useCookies } from 'react-cookie';

import dashboard from '../assets/icons/dashboard.svg';
import dashboard_gold from '../assets/icons/dashboard_gold.svg';
import user from '../assets/icons/user.svg';
import user_gold from '../assets/icons/user_gold.svg';
import cause from '../assets/icons/cause.svg';
import cause_gold from '../assets/icons/cause_gold.svg';
import organization from '../assets/icons/organization.png';
import organization_gold from '../assets/icons/organization_gold.png';
import reports from '../assets/icons/reports.png';
import reports_gold from '../assets/icons/reports_gold.png';
import lunar_dates from '../assets/icons/lunar_dates.png';
import lunar_dates_gold from '../assets/icons/lunar_dates_gold.png';
import lunar_months from '../assets/icons/lunar_months.png';
import lunar_months_gold from '../assets/icons/lunar_months_gold.png';
import group_dates from '../assets/icons/group_dates.png';
import group_dates_gold from '../assets/icons/group_dates_gold.png';

import bg from '../assets/bg.png';
import Bell from '../assets/icons/bell_2x.png';

import linkedin from '../assets/icons/linkedin.png';
import twitter from '../assets/icons/group-8805.png';
import instagram from '../assets/icons/group-8806.png';
import facebook from '../assets/icons/group-8807.png';

import { Context } from '../utils/store';
import axios from '../utils/axios';

import Dashboard from './Dashboard';
import User from './User';
import Organization from './Organization';
import Cause from './Cause';
import LunarMonths from './LunarMoths';
import LunarDates from './LunarDates';
import GroupDates from './GroupDates';
import Reports from './Reports';
import LearningCenterArticles from './LearningCenterArticles';

import PersonalImportDate from './PersonalImportDate';
import TransactionDetails from './TransactionDetails';
import LearningCenterArticlesDetails from './LearningCenterArticlesDetails';

import AddGroupDates from './AddGroupDates';
import AddOrganization from './AddOrganization';
import AddCause from './AddCause';
import AddLunarDates from './AddLunarDates';
import AddLunarMonth from './AddLunarMonth';
import StickyHeader from '../components/StickyHeader';
import AddPersonalDate from './AddPersonalDate';
import AddLearningCenterArticles from './AddLearningCenterArticles';

// import { MenuContext } from 'react-flexible-sliding-menu';

export default function Admin() {
  const { state, dispatch } = useContext(Context);
  //   const { toggleMenu } = useContext(MenuContext);

  const [page, setPage] = useState('Dashboard');
  const [currentPage, setCurrentPage] = useState(<></>);
  const [cookies] = useCookies();

  useEffect(() => {
    dispatch({ type: 'pagesList', pagesList: allPages });
    setPage(state?.adminCurrentPage);
    getUserData();
  }, []);

  useEffect(() => {
    setPage(state.adminCurrentPage);
  }, [state.adminCurrentPage]);

  const allPages = [
    { name: 'Dashboard', title: 'Dashboard' },
    { name: 'User', title: 'User' },
    { name: 'Organization', title: 'Organization' },
    { name: 'Cause', title: 'Cause' },
    { name: 'LunarMonths', title: 'Lunar Months' },
    { name: 'LunarDates', title: 'Lunar Dates' },
    { name: 'GroupDates', title: 'Group Dates' },
    { name: 'LearningCenterArticles', title: 'Learning Center Articles' },
  ];

  const getUserData = async () => {
    await axios({
      method: 'post',
      url: '/getuser',
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
    })
      .then((response) => {
        dispatch({ type: 'userInfo', userInfo: response.data.response });
      })
      .catch((err) => {
        console.log(err.response);
        dispatch({
          type: 'isLoggedIn',
          isLoggedIn: false,
        });
      });
  };
  const [lunarDates, setLunarDates] = React.useState(false);
  useEffect(() => {
    if (lunarDates == true) {
      setPage('LunarDates');
      setLunarDates(false);
    }
  }, [lunarDates]);

  const backToDashboard = () => {
    setPage('Dashboard');
  };
  useEffect(() => {
    // console.log(page)
    if (page == 'Dashboard') {
      setCurrentPage(
        <Dashboard action={() => setPage('TransactionDetails')} />
      );
    } else if (page == 'User') {
      setCurrentPage(<User />);
    } else if (page == 'Organization') {
      setCurrentPage(
        <Organization action={() => setPage('AddOrganization')} />
      );
    } else if (page == 'Cause') {
      setCurrentPage(<Cause action={() => setPage('AddCause')} />);
    } else if (page == 'LunarMonths') {
      setCurrentPage(<LunarMonths action={() => setPage('AddLunarMonth')} />);
    } else if (page == 'LunarDates') {
      setCurrentPage(<LunarDates action={() => setPage('AddLunarDates')} />);
    } else if (page == 'GroupDates') {
      setCurrentPage(
        <GroupDates
          action={() => setPage('AddGroupDates')}
          backToGroupDates={() => setPage('GroupDates')}
        />
      );
    } else if (page == 'AddGroupDates') {
      setCurrentPage(
        <AddGroupDates backToGroupDates={() => setPage('GroupDates')} />
      );
    } else if (page == 'AddOrganization') {
      setCurrentPage(
        <AddOrganization backToOrganzations={() => setPage('Organization')} />
      );
    } else if (page == 'AddLunarDates') {
      setCurrentPage(
        <AddLunarDates backToLunarDates={() => setPage('LunarDates')} />
      );
    } else if (page == 'AddCause') {
      setCurrentPage(<AddCause backToCauses={() => setPage('Cause')} />);
    } else if (page == 'PersonalImportDate') {
      setCurrentPage(
        <PersonalImportDate action={() => setPage('AddPersonalDate')} />
      );
    } else if (page == 'AddPersonalDate') {
      setCurrentPage(
        <AddPersonalDate
          backToPersonalDays={() => setPage('PersonalImportDate')}
        />
      );
    } else if (page == 'TransactionDetails') {
      setCurrentPage(<TransactionDetails backToDashboard={backToDashboard} />);
    } else if (page == 'AddLunarMonth') {
      setCurrentPage(
        <AddLunarMonth backToMonths={() => setPage('LunarMonths')} />
      );
    } else if (page == 'LearningCenterArticles') {
      setCurrentPage(
        <LearningCenterArticles
          openAddArticle={() => setPage('AddLearningCenterArticles')}
        />
      );
    } else if (page == 'AddLearningCenterArticles') {
      setCurrentPage(
        <AddLearningCenterArticles
          backToArticles={() => setPage('LearningCenterArticles')}
        />
      );
    } else if (page == 'LearningCenterArticlesDetails') {
      setCurrentPage(<LearningCenterArticlesDetails />);
    }
  }, [page]);

  return (
    <>
      <div className="md:px-10 w-full mb-3 flex flex-nowrap justify-between fixed-header">
        <StickyHeader />
      </div>
      <img
        className="hidden md:flex overscroll-x-none overscroll-y-none  absolute w-screen"
        src={bg}
      />

      {/* <div className="md:hidden bg-white w-max p-1 rounded-r-lg shadow-fullCard mb-4">
   <button 
    onClick={toggleMenu} 
   className="ml-4 items-center px-3 py-2 rounded text-teal-200">
        <svg className="fill-current text-camel h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg> 
      </button>
      <span className="text-gray-500 px-2 text-sm">{allPages.find(x => x.name === page).title}</span>
    </div> */}
      <div className="px-4 md:px-10 w-full h-screen/2 p-2 grid grid-cols-1 auto-cols-max md:grid-cols-4 ">
        <div className="hidden md:flex px-2 bg-white h-screen/2 md:px-4 2xl:px-12 md:mr-8 mb-8 rounded-side md:relative text-side fixed-menu-col">
          <div className="relative h-full w-full z-20">
            <button
              onClick={() => setPage('Dashboard')}
              className={
                page != 'Dashboard' && page != 'TransactionDetails'
                  ? 'z-50 flex flex-row text-gray-500 p-4 focus:outline-none w-full text-left mt-10 text-sm 2xl:text-side font-Poppins'
                  : 'z-20 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex-row w-full text-left mt-10 text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-3 ml-2 mt-auto mb-auto "
                src={
                  page != 'Dashboard' && page != 'TransactionDetails'
                    ? dashboard
                    : dashboard_gold
                }
              />
              Dashboard
            </button>
            <button
              onClick={() => setPage('User')}
              className={
                page != 'User'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100  focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-3 ml-2 mt-auto mb-auto "
                src={page != 'User' ? user : user_gold}
              />
              User
            </button>
            <button
              onClick={() => setPage('Organization')}
              className={
                page != 'Organization' && page != 'AddOrganization'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={
                  page != 'Organization' && page != 'AddOrganization'
                    ? organization
                    : organization_gold
                }
              />
              Organization
            </button>
            <button
              onClick={() => setPage('Cause')}
              className={
                page != 'Cause' && page != 'AddCause'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'Cause' && page != 'AddCause' ? cause : cause_gold}
              />
              Cause
            </button>
            <button
              onClick={() => setPage('LunarMonths')}
              className={
                page != 'LunarMonths' && page != 'AddLunarMonth'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={
                  page != 'LunarMonths' && page != 'AddLunarMonth'
                    ? lunar_months
                    : lunar_months_gold
                }
              />
              Lunar Months
            </button>
            <button
              onClick={() => setPage('LunarDates')}
              className={
                page != 'LunarDates' && page != 'AddLunarDates'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={
                  page != 'LunarDates' && page != 'AddLunarDates'
                    ? lunar_dates
                    : lunar_dates_gold
                }
              />
              Lunar Dates
            </button>
            <button
              onClick={() => setPage('GroupDates')}
              className={
                page != 'GroupDates' && page != 'AddGroupDates'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={
                  page != 'GroupDates' && page != 'AddGroupDates'
                    ? group_dates
                    : group_dates_gold
                }
              />
              Group Dates
            </button>
            {/* <button
          onClick={() => setPage('Reports')}
            className={
              page != 'Reports'
                ? "z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins"
                : "z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins"
            }
          >
            <img
              className="mr-2 ml-2 mt-auto mb-auto"
              src={page != 'Reports' ? reports : reports_gold}
            />
            Reports
          </button> */}
            <button
              onClick={() => setPage('PersonalImportDate')}
              className={
                page != 'PersonalImportDate'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'PersonalImportDate' ? reports : reports_gold}
              />
              Personal Days
            </button>

            <button
              onClick={() => setPage('LearningCenterArticles')}
              className={
                page != 'LearningCenterArticles'
                  ? 'z-50 text-gray-500  p-4 focus:outline-none w-full text-left flex text-sm 2xl:text-side font-Poppins'
                  : 'z-50 bg-gray-100 focus:outline-none text-camel rounded-3xl p-4 flex flex w-full text-left text-sm 2xl:text-side font-Poppins'
              }
            >
              <img
                className="mr-2 ml-2 mt-auto mb-auto"
                src={page != 'LearningCenterArticles' ? reports : reports_gold}
              />
              Learning Center Articles
            </button>

            <div className="absolute bottom-4 2xl:bottom-10 h-auto w-full">
              <div className="justify-center flex flex-wrap">
                <a
                  href="mailto:salaam@charitymoments.com"
                  className="text-brownGrey relative md:absolute bottom:24 xl:bottom-32 2xl:bottom-32 p-2 text-sm 2xl:text-base hover:opacity-80"
                >
                  salaam@charitymoments.com
                </a>
                <div
                  style={{ width: 300 }}
                  className="relative md:absolute bottom-20 2xl:bottom-24 justify-center p-2 flex flex-row "
                >
                  <p className="mt-auto text-xs 2xl:text-sm mb-auto mr-2 text-brownGrey">
                    Terms of Service &nbsp;&nbsp;|&nbsp;&nbsp;{' '}
                    <a href="/privacypolicy">Privacy Policy</a>
                  </p>
                  {/* <p>|</p>
              <p className="mt-auto text-xs 2xl:text-sm mb-auto ml-2">Privacy Policy</p> */}
                </div>
              </div>
              <div className="justify-center flex flex-wrap lg:mb-8 sm:mb-3 relative">
                {/* Made with ❤️ in NYC */}
                <a
                  className="hover:opacity-80"
                  href="https://www.facebook.com/gocharitymoments"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={facebook} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://twitter.com/Charity_Moments"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={twitter} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.linkedin.com/company/charitymoments/"
                  target="_blank"
                >
                  <img className="h-8 mr-2" src={linkedin} />
                </a>
                <a
                  className="hover:opacity-80"
                  href="https://www.instagram.com/charitymoments/"
                  target="_blank"
                >
                  <img className="h-8" src={instagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 bg-white pb-8 pt-8 md:px-12 mb-10 min-h-screen/2 rounded-side col-span-3 relative md:mb-8 xl:lg:col-start-2 lg:col-start-2 md:col-start-2">
          {/* <div className="mt-8 mb-6">
     
            
           
            <h3 className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mb-4 z-50 font-Montserrat">
            {allPages.find((x) => x.name === page)?.title}
            </h3>
            <hr/>
            
            
        </div> */}
          {currentPage}
        </div>
      </div>
    </>
  );
}
