import React, {useState, useEffect} from 'react'
import Table from '../components/Table';
import axios from '../utils/axios'
import {Context} from '../utils/store';
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";
import LearningCenterArticlesDetails from './LearningCenterArticlesDetails';

export default function LearningCenterArticles(props:any) {
	const [cookies] = useCookies();
	const Toast = Swal.mixin({
		toast: true,
		position: "top",
		showConfirmButton: false,
		timer: 5000,
    });
      
	const initialTableData = [
		{title: 'Article Title', contentType: 'text', data: []},
        {title: 'Action', contentType: 'button', buttonName: 'View', buttonType: 'default', action: null},
		{title: 'Status', contentType: 'button', buttonType: 'status', status: [], action: null},
	]
	
	const ArticleTitleIndex = 0;
	const ActionIndex = 1;
	const StatusIndex = 2;

	const {state, dispatch} = React.useContext(Context)
	const [articlesData, setArticlesData] = React.useState<Array<any> | null>(null)
	const [isTableShown, setIsTableShown] = React.useState(false)
	const [tableData, setTableData] = React.useState(initialTableData)

    useEffect(() => {
        console.log(state.learningCenterArticles)
		if(state?.learningCenterArticles){
            setArticlesData(state?.learningCenterArticles)
        }else{
        axios.get('/learning-center')
            .then((response)=>{ 
                // Format publishedAt date:
                let newArticles = [...response.data.articles];
                newArticles.forEach((article, index)=>{
					if(article.publishedAt){
						let [year, month, day] = article.publishedAt.substring(0,10).split("-");
						let newPublishedAt = `${month}-${day}-${year}`
						newArticles[index].publishedAt = newPublishedAt;
					}
                })
				setArticlesData(newArticles)
				dispatch({ type: 'learningCenterArticles', learningCenterArticles: newArticles })
            })
            .catch(err=>{
				console.log(err)
			})
		}
	},[state])
	
	useEffect(()=>{
    	setTableDataHandler()
    },[articlesData])

	const setTableDataHandler = () => {
		let articleTitles = [];
		let statuses = [];

		if(articlesData){
            console.log(articlesData)
			articlesData.forEach((oneArticleData)=>{
				articleTitles.push(oneArticleData.title)
				if(oneArticleData?.isPublished){
					statuses.push(true)
				}
				else if(!oneArticleData?.isPublished){
					statuses.push(false)
				}
			})
		}

		let newTableData = [...tableData]
		newTableData[ArticleTitleIndex].data = articleTitles; 
		newTableData[ActionIndex].action = viewHandler;
		newTableData[StatusIndex].status = statuses; 
		newTableData[StatusIndex].action = statusHandler; 
		setTableData(newTableData)
		setIsTableShown(true)
	}

	const statusHandler = (index) => {
		let newTableData = [...tableData]
		const data = {
			isPublished: !newTableData[StatusIndex].status[index],
		}
		axios({
			method: "post",
			url: "/changearticlestatus/" + articlesData[index]._id,
			headers: {
			  Authorization: `Bearer ${cookies.tokenId}`,
			},
			data: data,
		  }).then((response)=>{
			newTableData[StatusIndex].status[index] = !newTableData[StatusIndex].status[index]
			setTableData(newTableData)
			let newArticles = [...articlesData];
        	newArticles[index].isPublished = newTableData[StatusIndex].status[index]
        	dispatch({ type: 'learningCenterArticles', learningCenterArticles: newArticles })
			Toast.fire({
				title: 'Status has been changed successfully',
				icon: "success",
			});
		  })
    }
    
	const [showArticleDetails, setShowArticleDetails] = useState(false)
	const [articleDetailsID, setArticleDetailsID] = useState(null)

	const viewHandler = (index:number) => {
		setShowArticleDetails(true)
		setArticleDetailsID(articlesData[index]._id)
	}
	
	if(showArticleDetails && articleDetailsID){
		return (<LearningCenterArticlesDetails id={articleDetailsID} backToArticles={()=>setShowArticleDetails(false)} />)
	}else{
    return (<>
        <div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className={`pt-4  rounded-3xl   `}>
            <div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
                <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Learning Center Articles</p>
				<button 
                    onClick={() => {props.openAddArticle()}}
                    style={{color:'#c7ad1f', boxShadow:' 0 1.5px 5px 0 rgba(56, 66, 115, 0.11)'}}
                    className="focus:outline-none bg-white rounded-3xl text-white text-sm md:text-bas ml-auto font-Poppins lg:w-48 w-full ml-auto h-12 lg:mt-auto lg:mb-auto mb-2 py-3  z-20 relative hover:opacity-80 ">
                    Add New
                </button>
            </div>
            { isTableShown ? <Table tableData={tableData} rows={articlesData?.length}/> : null }
        </div>
	</>)
	}
}