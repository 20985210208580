import React, {useContext} from 'react'
import CheckBox from '../components/CheckBox'
import Swal from "sweetalert2";
import Button from '../components/Button'
import { useCookies } from "react-cookie";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Dropdown from 'react-dropdown';
import { Context } from "../utils/store";
import axios from '../utils/axios'
import '../styles/dropdown.css';
import moment from "moment";
import DatePicker from 'react-modern-calendar-datepicker';
import { defaultDateFormat } from '../utils/date-unitls';
export default function AddLunarDates(props:any) {
  const { state, dispatch } = useContext(Context);

  const [lunarDates, setLunarDates] = React.useState<Array<any> | null>([]);
  const [notification, setNotification] = React.useState<Array<any> | null>([]);
  const [monthName, setMonthName] = React.useState()
  const [monthId, setMonthId] = React.useState()
  const [lunarDateName, setLunarDateName] = React.useState('')
  const [monthsData, setmonthsData] = React.useState<Array<any> | null>(null)
  const [causeBelongingOptions, setCauseBelongingOptions] = React.useState()
  const [calendarDate, setCalendarDate] = React.useState()
  const [notificationDate, setNotificationDate] = React.useState()
  const [cookies] = useCookies();
  const [date, setDate] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(false)
  const [isNight, setIsNight] = React.useState(false)
  const [dayOfmonth, setDayOfmonth] = React.useState()
  const [showInCheckout, setShowInCheckout] = React.useState(true)

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
  });

  React.useEffect(() => {
    axios.get('/islamicmonths')
      .then((response) => {
        setmonthsData(response.data.response)
      })
      .catch(err => {
      })
  }, [])

  const Handler = (value: any, index: any) => {
    let newNotification = [...notification]
    newNotification[index].date = value;
    setNotification(newNotification)
  }

  //Месяц
  React.useEffect(() => {
    let newCauseBelongingOptions = monthsData?.map((oneCharity) => oneCharity.name)
    setCauseBelongingOptions(newCauseBelongingOptions)
  }, [monthsData])

  React.useEffect(() => {
    if (monthsData) {
      monthsData.forEach((oneCause) => {
        if (oneCause.name == monthName) {
          setMonthId(oneCause._id)
        }
      })
    }
  }, [monthName])

  const addNow = () => {
    setLoading(true);
    const params = {
      islamicMonthId: monthId,
      name: lunarDateName,
      calendarDate: moment(calendarDate).startOf('day').format(),
      dayOfMonth: dayOfmonth,
      showInCheckout,
      notificationDate: moment(notificationDate).startOf('day').format(),
      status: true,
      isNight: isNight,
      isActive: false,
    }
    axios({
      method: "post",
      url: "/islamicdays",
      headers: {
        Authorization: `Bearer ${cookies.tokenId}`,
      },
      data: params,
    })
      .then(async (response: any) => {
        setLoading(false);
        Toast.fire({
          title: `Lunar date ${lunarDateName} has been added`,
          icon: "success",
        })
        let newIslamicdays = [...state.islamicdays]
        let newIslamicDay = {...response.data.response}
        newIslamicDay.calendarDate = defaultDateFormat(newIslamicDay.calendarDate)
        newIslamicDay.notificationDate = defaultDateFormat(newIslamicDay.notificationDate)

        newIslamicdays.push(newIslamicDay)
        dispatch({ type: 'islamicdays', islamicdays: newIslamicdays })
        props.backToLunarDates()
        setLoading(false)
      })
      .catch((err: any) => {
        console.log(err)
        setLoading(false);
        Toast.fire({
          title: "Error",
          icon: "error",
        })
      });
  }

  const renderCustomNotificationInput = (ref) => {
    
    let formatedNotificationDate = notificationDate?.split("-")?.join("/")

    return (
      <input
        ref={ref}
        value={formatedNotificationDate ? formatedNotificationDate : null}
        placeholder="MM/DD/YY"
        style={{
          width: '12rem',
          backgroundColor: '#fafafa',
          textAlign: "center",
          padding: ".3rem .7rem",
          boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)",
          borderRadius: "5px",
          color: "black",
          outline: "none",
        }}
        className="my-custom-input-class z-30 relative" // a styling class
      />
    );
  };

  const renderCustomCalendarDateInput = (ref) => {

    let formatedCalendarDate = calendarDate?.split("-")?.join("/")

    return (
      <input
        ref={ref}
        value={formatedCalendarDate? formatedCalendarDate : null}
        placeholder="MM/DD/YY"
        style={{
          width: '12rem',
          backgroundColor: '#fafafa',
          textAlign: "center",
          padding: ".3rem .7rem",
          boxShadow: "0.5px 1px 5px 0 rgba(0, 0, 0, 0.07)",
          borderRadius: "5px",
          color: "black",
          outline: "none",
        }}
        className="my-custom-input-class z-30 relative" // a styling class
      />
    );
  };

  const calendarDateHandler = (value: any, index: any) => {
    const day = ('0' + value.day).slice(-2)
    const month = ('0' + value.month).slice(-2)
    const year = value.year;

    const date = month + "-" + day + "-" + year;
    setCalendarDate(date)
  }

  const notificationDateHandler = (value: any, index: any) => {
    const day = ('0' + value.day).slice(-2)
    const month = ('0' + value.month).slice(-2)
    const year = value.year;
    const date = month + "-" + day + "-" + year;
    setNotificationDate(date)
  }

  return (<>
    <div style={{ backgroundColor: '#f6f6f6', boxShadow: '0 2.5px 5px 0 rgba(56, 66, 115, 0.15)' }} className={`pt-4  rounded-3xl   `}>
      <div className='grid lg:flex lg:flex-row md:grid-cols-1 2xl:px-8 lg:px-8 px-2 pb-4 ' >
        <p className="inline-block text-xl xl:text-2xl mt-0 xl:-ml-0 ml-4 lg:ml-2 mt-auto mb-auto z-50 font-PoppinsSemiBold ml-auto mr-auto lg:mr-2 pb-3 lg:pb-0">Lunar Dates</p>
      </div>
      <div className='bg-white px-8   '>
        <div className='bg-white table  '>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Lunar Date</p>
              <input
                onChange={(e) => setLunarDateName(e.target.value)}
                style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}
                className={`mt-auto mb-auto table-cell rounded-md w-72 2xl:mr-0 flex-grow    mb-5 2xl:mb-0 focus:ring-white  w-full  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                placeholder='Lunar Date Name'
              />
            </div>
          </div>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Lunar Month</p>
              <div className=' table-cell relative z-40 w-72'>
                <Dropdown options={causeBelongingOptions} onChange={(e) => setMonthName(e.value)} placeholder="Choose Lunar Month" className='font-Poppins text-sm 2xl:text-base ' />
              </div>

            </div>
          </div>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Calendar Date Map</p>
              <div className='-ml-10'>
              <div className='flex flex-row py-6'>
                <div className="inline-block md:justify-self-end  ml-10 z-30">
                    <DatePicker
                      value={null}
                      onChange={(value) => calendarDateHandler(value)}
                      calendarPopperPosition="bottom"
                      renderInput={({ ref }) => renderCustomCalendarDateInput(ref)}
                      shouldHighlightWeekends
                      wrapperClassName="z-10"
                    />
                  </div>
              </div>
              </div>

            </div>
          </div>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Notification Date</p>
              <div className='-ml-10'>
              <div className='flex flex-row py-6'>
                <div className="inline-block md:justify-self-end  ml-10 z-30">
                  <DatePicker
                    value={null}
                    onChange={(value) => notificationDateHandler(value)}
                    calendarPopperPosition="bottom"
                    renderInput={({ ref }) => renderCustomNotificationInput(ref)}
                    shouldHighlightWeekends
                    wrapperClassName="z-10"
                  />
                </div>
              </div>
              </div>

            </div>
          </div>
          <div className="table-row-group">
            <div className='table-row flex flex-row'>
              <p className='font-Poppins mt-auto mb-auto 2xl:text-base text-sm py-6 pr-4  md:pr-10  pt-8 table-cell'>Day of Month</p>
              <input
                onChange={(e) => setDayOfmonth(e.target.value)}
                type="number"
                style={{ backgroundColor: '#fff', border: '1px solid #ccc' }}
                className={`mt-auto mb-auto table-cell rounded-md w-72 2xl:mr-0 flex-grow    mb-5 2xl:mb-0 focus:ring-white  w-full  font-Poppins focus:ring-2 placeholder-grey2   focus:outline-none  block   pl-4 pr-4 sm:text-sm text-xs 2xl:text-base   h-10 z-20 relative  `}
                placeholder='Day of Month'
              />
            </div>
          </div>
          <div className='mt-5 mb-10 font-Poppins relative z-20'> <CheckBox label='Show in checkout' isChecked={showInCheckout} setIsChecked={()=>setShowInCheckout(!showInCheckout)} /></div>
          <div className='mt-5 mb-10 font-Poppins relative z-20'> <CheckBox label='Night schedule' isChecked={isNight} setIsChecked={()=>setIsNight(!isNight)} /></div>
          <div className='md:w-40 pb-4 mt-10'> <Button loading={loading} action={addNow} title='Add Now' /></div>
        </div>

      </div>

    </div>
  </>)
}