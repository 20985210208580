import React from 'react'
import { CgSpinner } from "react-icons/cg";

export default function Button(props:any) {

    if(props.type=="delete"){
    return (
        <button 
            onClick={props.action} 
            className="focus:outline-none bg-red rounded text-white text-xs md:text-base font-Poppins shadow-button py-3 z-20 relative hover:opacity-80 w-full">
            {props.loading ? <CgSpinner size="25" className="absolute left-4 animate-spin"/> : null}
            {props.title}
        </button>
        )
    }
    else{
        return (
        <button 
            onClick={props.action} 
            className="focus:outline-none bg-camel rounded text-white text-xs md:text-base font-Poppins shadow-button py-3 z-20 relative hover:opacity-80 w-full">
            {props.loading ? <CgSpinner size="25" className="absolute left-4 animate-spin"/> : null}
            {props.title}
        </button>
        )
    }
}